import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import Warning from '@material-ui/icons/Warning';
import DrcAuthenticationUtilities from '../Utilities/DrcAuthenticationUtilities';

const styles = (theme) => ({
    heading: {
        display: 'inline-block',
        margin: '20px 20px 0 20px',
        position: 'relative',
        top: '-15px'
    },
    icon: {
        fontSize: '4rem'
    }
});

class DrcPageNotAuthorized extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: null
        };

        this.checkForAuthentication = this.checkForAuthentication.bind(this);
    }

    async checkForAuthentication() {
        this.props.auth
            .isAuthenticated()
            .then((isAuthenticated) => {
                if (this.state.isAuthenticated != isAuthenticated) {
                    this.setState({ isAuthenticated });
                }

                if (!isAuthenticated) {
                    var credentials = DrcAuthenticationUtilities.GetUsernameAndPasswordFromQuery(this.props.location.search);

                    if (credentials !== null) {
                        DrcAuthenticationUtilities.SignIn(credentials.username, credentials.password);
                    }
                }
            })
            .catch(() => {
                console.error('ERROR: Authentication not available');
                if (this.state.isAuthenticated != false) {
                    this.setState({ isAuthenticated: false });
                }
            });
    }

    componentDidUpdate() {
        this.checkForAuthentication();
    }

    componentDidMount() {
        this.checkForAuthentication();
    }

    render() {
        const { logOutUrl, logInUrl, classes } = this.props;

        return (
            <DrcMain transparent>
                <Helmet>
                    <title>Not Authorized</title>
                </Helmet>
                <DrcPanel error maxWidth="500px" style={{ textAlign: 'center' }}>
                    <Warning className={classes.icon} />
                    <h1 className={classes.heading}>You are not authorized!</h1>
                    <Warning className={classes.icon} />
                    <br />
                    {this.state.isAuthenticated ? (
                        <p>
                            Please <Link to={logOutUrl || '/LogOut/'}>Sign-Out</Link> and try with better credentials.
                        </p>
                    ) : (
                        <p>
                            Please go back to the <Link to={logInUrl || '/'}>Sign-In</Link> page and try again.
                        </p>
                    )}
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default withAuth(withStyles(styles)(withRouter(DrcPageNotAuthorized)));
