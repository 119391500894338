import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcButton from './DrcButton';
import DrcImage from './DrcImage';
import { DuThemeUtilities } from 'driscolls-react-utilities';

const BERRY_STRING_VALUES = ['EMPTY', 'BLACK', 'BLUE', 'RASP', 'STRAW'];
const BERRY_BLACK = 1;
const BERRY_BLUE = 2;
const BERRY_RASP = 3;
const BERRY_STRAW = 4;

var styles = (theme) => ({
    berryBtn: {
        margin: '0',
        display: 'block',
        borderRadius: 0,
        width: '100%'
    },
    variantVertical: {
        borderBottom: 'none !important',
        '&:last-of-type': {
            borderBottom: `1px solid ${theme.palette.primary.main} !important`
        }
    },
    variantHorizontal: {
        borderRight: 'none !important',
        '&:last-of-type': {
            borderRight: `1px solid ${theme.palette.primary.main} !important`
        }
    },
    berryFontBlack: {
        color: DuThemeUtilities.DefaultColors.primary.purple
    },
    berryFontBlue: {
        color: DuThemeUtilities.DefaultColors.primary.blue
    },
    berryFontRasp: {
        color: DuThemeUtilities.DefaultColors.primary.pink
    },
    berryFontStraw: {
        color: DuThemeUtilities.DefaultColors.primary.red
    },
    selected: {
        '&.berryBlack': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.purple + ' !important'
        },
        '&.berryBlue': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.blue + ' !important'
        },
        '&.berryRasp': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.pink + ' !important'
        },
        '&.berryStraw': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red + ' !important'
        },
        '& .berryFont': {
            color: DuThemeUtilities.DefaultColors.text.white.light + ' !important'
        },
        '& .berryIcon': {
            boxShadow: 'inset 0 0 10px 0px #888'
        }
    },
    berryFont: {
        fontFamily: 'BlogScript',
        textTransform: 'none',
        width: '100%',
        display: 'inline-block',
        fontSize: '1.5rem'
    },
    berryFontVerticalOffset: {
        marginTop: 20
    },
    berryIcon: {
        height: 60,
        width: 60,
        borderRadius: 30,
        display: 'inline-block',
        backgroundColor: '#FFF',
        '& img': {
            marginTop: 10,
            height: 40
        }
    },
    btnContentContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    btnTextContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    btnIconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    horizontalList: {
        display: 'flex',
    }
});

class DrcBerryList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            berryType: this.props.selectedBerry || 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedBerry !== 0 && this.props.selectedBerry === 0) this.setState({ berryType: 0 });
    }
    handleBerryClick = (val) => {
        if (val === this.state.berryType) {
            return;
        }

        this.setState({
            berryType: val
        });

        const callback = this.props.onClick || (() => {});

        callback(this.props.useText ? BERRY_STRING_VALUES[val] : val);
    };

    render() {
        const { className, classes, disabledBerries, hiddenBerries, iconSizeStyle, berryBtnStyle, berryFontStyle, berryIconStyle, isHorizontal } = this.props;
        const { berryType } = this.state;

        const disabledBerriesList = disabledBerries || [];
        const hiddenBerriesList = hiddenBerries || [];
        const btnBorderStyle = isHorizontal ? classes.variantHorizontal : classes.variantVertical;
        const listDirection = isHorizontal ? classes.horizontalList : '';

        return (
            <div className={`${listDirection} ${className}`}>
                {hiddenBerriesList.includes(BERRY_BLACK) ? null : (
                    <DrcButton
                        isSecondary
                        noStyle
                        disabled={disabledBerriesList.includes(BERRY_BLACK)}
                        className={`${classes.berryBtn} ${btnBorderStyle} ${berryBtnStyle} ${berryType === BERRY_BLACK ? classes.selected : ''} berryBlack`}
                        onClick={() => this.handleBerryClick(BERRY_BLACK)}
                    >
                        {!isHorizontal ? (
                            <div className="row">
                                <div className="col-xs-9">
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontVerticalOffset} ${classes.berryFontBlack} ${berryFontStyle}`}>
                                        Blackberry
                                    </span>
                                </div>
                                <div className="col-xs-3">
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Black_Medium.png')} webp={require('./Images/Black_Medium.webp')} />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.btnContentContainer}>
                                <div className={classes.btnIconContainer}>
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Black_Medium.png')} webp={require('./Images/Black_Medium.webp')} />
                                    </span>
                                </div>
                                <div className={classes.btnlabelContainer}>
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontBlack} ${berryFontStyle}`}>Blackberry</span>
                                </div>
                            </div>
                        )}
                    </DrcButton>
                )}
                {hiddenBerriesList.includes(BERRY_BLUE) ? null : (
                    <DrcButton
                        isSecondary
                        noStyle
                        disabled={disabledBerriesList.includes(BERRY_BLUE)}
                        className={`${classes.berryBtn} ${btnBorderStyle}  ${berryType === BERRY_BLUE ? classes.selected : ''} berryBlue`}
                        onClick={() => this.handleBerryClick(BERRY_BLUE)}
                    >
                        {!isHorizontal ? (
                            <div className="row">
                                <div className="col-xs-9">
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontVerticalOffset} ${classes.berryFontBlue} ${berryFontStyle}`}>
                                        Blueberry
                                    </span>
                                </div>
                                <div className="col-xs-3">
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Blue_Medium.png')} webp={require('./Images/Blue_Medium.webp')} />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.btnContentContainer}>
                                <div className={classes.btnIconContainer}>
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Blue_Medium.png')} webp={require('./Images/Blue_Medium.webp')} />
                                    </span>
                                </div>
                                <div className={classes.btnlabelContainer}>
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontBlue} ${berryFontStyle}`}>Blueberry</span>
                                </div>
                            </div>
                        )}
                    </DrcButton>
                )}
                {hiddenBerriesList.includes(BERRY_RASP) ? null : (
                    <DrcButton
                        isSecondary
                        noStyle
                        disabled={disabledBerriesList.includes(BERRY_RASP)}
                        className={`${classes.berryBtn} ${btnBorderStyle}  ${berryType === BERRY_RASP ? classes.selected : ''} berryRasp`}
                        onClick={() => this.handleBerryClick(BERRY_RASP)}
                    >
                        {!isHorizontal ? (
                            <div className="row">
                                <div className="col-xs-9">
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontVerticalOffset} ${classes.berryFontRasp} ${berryFontStyle}`}>
                                        Raspberry
                                    </span>
                                </div>
                                <div className="col-xs-3">
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Rasp_Medium.png')} webp={require('./Images/Rasp_Medium.webp')} />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.btnContentContainer}>
                                <div className={classes.btnIconContainer}>
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Rasp_Medium.png')} webp={require('./Images/Rasp_Medium.webp')} />
                                    </span>
                                </div>
                                <div className={classes.btnlabelContainer}>
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontRasp} ${berryFontStyle}`}>Raspberry</span>
                                </div>
                            </div>
                        )}
                    </DrcButton>
                )}
                {hiddenBerriesList.includes(BERRY_STRAW) ? null : (
                    <DrcButton
                        isSecondary
                        noStyle
                        disabled={disabledBerriesList.includes(BERRY_STRAW)}
                        className={`${classes.berryBtn} ${btnBorderStyle}  ${berryType === BERRY_STRAW ? classes.selected : ''} berryStraw`}
                        onClick={() => this.handleBerryClick(BERRY_STRAW)}
                    >
                        {!isHorizontal ? (
                            <div className="row">
                                <div className="col-xs-9">
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontVerticalOffset} ${classes.berryFontStraw} ${berryFontStyle}`}>
                                        Strawberry
                                    </span>
                                </div>
                                <div className="col-xs-3">
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Straw_Medium.png')} webp={require('./Images/Straw_Medium.webp')} />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.btnContentContainer}>
                                <div className={classes.btnIconContainer}>
                                    <span className={`berryIcon ${classes.berryIcon}  ${berryIconStyle}`}>
                                        <DrcImage className={iconSizeStyle} src={require('./Images/Straw_Medium.png')} webp={require('./Images/Straw_Medium.webp')} />
                                    </span>
                                </div>
                                <div className={classes.btnlabelContainer}>
                                    <span className={`berryFont ${classes.berryFont} ${classes.berryFontStraw} ${berryFontStyle}`}>Strawberry</span>
                                </div>
                            </div>
                        )}
                    </DrcButton>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(DrcBerryList);
