//BEGIN - Dialogs

export const OPEN_CLOSE_CANCEL_DIALOG = 'OC_CANCEL_DIALOG';
export const openCloseCancelDialog = (poRef) => ({
    type: OPEN_CLOSE_CANCEL_DIALOG,
    payload: { poRef }
});

export const OPEN_CLOSE_CHANGE_STATUS_DIALOG = 'OPEN_CLOSE_CHANGE_STATUS_DIALOG';
export const openCloseChangeStatusDialog = (title, status, isReview) => ({
    type: OPEN_CLOSE_CHANGE_STATUS_DIALOG,
    payload: { title, status, isReview }
});
//END - Dialogs

//BEGIN - Quotes

export const SET_QUOTE_LIST = 'SET_QUOTE_LIST';
export const setQuotes = (quoteList, ref) => ({
    type: SET_QUOTE_LIST,
    payload: { quoteList, ref }
});

export const CLEAR_QUOTE_LIST = 'CLEAR_QUOTE_LIST';
export const clearQuotes = () => ({
    type: CLEAR_QUOTE_LIST,
    payload: {}
});

export const ADD_SELECTED_QUOTE = 'ADD_SELECTED_QUOTE';
export const setSelected = (selectedQuote) => ({
    type: ADD_SELECTED_QUOTE,
    payload: { selectedQuote }
});

export const REMOVE_SELECTED_QUOTE = 'REMOVE_SELECTED_QUOTE';
export const clearSelected = () => ({
    type: REMOVE_SELECTED_QUOTE,
    payload: {}
});

export const SET_QUOTE_DETAILS_ID = 'SET_QUOTE_DETAILS_ID';
export const openCloseQuoteDetails = (quoteId, poRef) => ({
    type: SET_QUOTE_DETAILS_ID,
    payload: { quoteId, poRef }
});

export const SET_QUOTE_DETAILS = 'SET_QUOTE_DETAILS';
export const setQuoteDetails = (options) => ({
    type: SET_QUOTE_DETAILS,
    payload: { options }
});

//END - Quotes

//BEGIN - Filters

export const SET_PO_FILTERS = 'SET_PO_FILTERS';
export const setPoFiltersAction = (name, option) => ({
    type: SET_PO_FILTERS,
    payload: {
        name,
        option
    }
});

export const CLEAR_PO_FILTERS = 'CLEAR_PO_FILTERS';
export const clearPoFiltersAction = () => ({
    type: CLEAR_PO_FILTERS,
    payload: {}
});

export const CLEAR_ONE_FILTER = 'CLEAR_ONE_FILTER';
export const clearOneFilter = (filter) => ({
    type: CLEAR_ONE_FILTER,
    payload: { filter }
});

export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const setAllFilters = (filters) => ({
    type: SET_ALL_FILTERS,
    payload: { filters }
});

//END - Filters

//BEGIN - PO

export const ADD_PO_LIST = 'ADD_PO_LIST';
export const setPoList = (data, clear, salesReps) => ({
    type: ADD_PO_LIST,
    payload: { data, clear, salesReps }
});

export const SET_PO_REVIEW_LIST = 'SET_PO_REVIEW_LIST';
export const setPoReviewList = (data) => ({
    type: SET_PO_REVIEW_LIST,
    payload: { data }
});

export const ADD_PO_LINE_ITEMS = 'ADD_PO_LINE_ITEMS';
export const setPoLineItems = (PoHeaderId, items) => ({
    type: ADD_PO_LINE_ITEMS,
    payload: { PoHeaderId, items }
});

export const SET_SELECTED_PO = 'SET_SELECTED_PO';
export const setSelectedPo = (poRef, isSelected, forceUpdateSelectedPo) => ({
    type: SET_SELECTED_PO,
    payload: { poRef, isSelected, forceUpdateSelectedPo }
});
export const SET_SELECTED_PO_REF_IN_SO = 'SET_SELECTED_PO_REF_IN_SO';
export const setSelectedPoRefInSo = (poRef) => ({
    type: SET_SELECTED_PO_REF_IN_SO,
    payload: { poRef }
});
export const SET_SELECTED_PO_REF_IN_EDIT = 'SET_SELECTED_PO_REF_IN_EDIT';
export const setSelectedPoRefEdit = (poRef) => ({
    type: SET_SELECTED_PO_REF_IN_EDIT,
    payload: { poRef }
});

export const CLEAR_SELECTED_PO = 'CLEAR_SELECTED_PO';
export const clearSelectedPo = () => ({
    type: CLEAR_SELECTED_PO,
    payload: {}
});

export const SET_SELECTED_REVIEW_PO = 'SET_SELECTED_REVIEW_PO';
export const setSelectedReviewPo = (poRef) => ({
    type: SET_SELECTED_REVIEW_PO,
    payload: { poRef }
});

export const CLEAR_SELECTED_REVIEW_PO = 'CLEAR_SELECTED_REViEW_PO';
export const clearSelectedReviewPo = () => ({
    type: CLEAR_SELECTED_REVIEW_PO,
    payload: {}
});

export const SET_SHOW_PO_LINE_ITEMS = 'SET_SHOW_PO_LINE_ITEMS';
export const setShowPoLineItems = (poRef, ShowLineItems) => ({
    type: SET_SHOW_PO_LINE_ITEMS,
    payload: { poRef, ShowLineItems }
});

//END - PO
