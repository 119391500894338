import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    label: {
        float: 'right'
    },
    root: {
        '& .react-grid-HeaderCell input': {
            maxWidth: '100%',
            height: 25
        },
        '& .react-grid-Toolbar': {
            position: 'relative',
            right: 0,
            zIndex: 1,
            backgroundColor: 'transparent',
            padding: 0,
            border: 'none',
            height: 0
        },
        '& .react-grid-Toolbar .tools': {
            marginTop: 0,
            height: 0
        },
        '& .react-grid-Toolbar .btn': {
            visibility: 'hidden',
            padding: '3px'
        },
        '& .react-grid-Toolbar .btn:after': {
            content: "'⋙'",
            fontSize: '1.5rem',
            display: 'block',
            position: 'absolute',
            padding: '3px 5px 5px',
            top: '1px',
            right: 0,
            background: 'linear-gradient(180deg, ' + theme.light.grid.color1 + ' 0%, ' + theme.light.grid.color2 + ' 80%, ' + theme.light.grid.color3 + ' 100%)',
            visibility: 'visible'
        },
        '& .react-grid-Toolbar button.btn:hover:after': {
            content: '"Filters only what is loaded on screen"',
            padding: '8px',
            background: theme.light.secondary,
            borderRadius: '5px',
            margin: '0',
            fontSize: '1rem'
        },
        '& .react-grid-HeaderRow:first-of-type .react-grid-HeaderCell div': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '600'
        },
        '& .react-grid-Cell > div > div': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& span.Select-arrow-zone': {
            paddingRight: '0 !important',
            width: 15
        },
        '& .Select.has-value.is-clearable.Select--single > .Select-control .Select-value': {
            padding: '2px 30px 2px 4px'
        },
        '& .react-grid-HeaderCell .Select-control': {
            maxHeight: 23,
            border: '1px solid #767676',
            borderRadius: 0
        },
        '& .react-grid-HeaderCell .Select-control .Select-input': {
            maxHeight: 23
        },
        '& .react-grid-HeaderCell .Select-control .Select-placeholder': {
            padding: '2px 4px'
        }
    },
    radio: {
        '& .react-grid-checkbox + .react-grid-checkbox-label:before': {
            borderRadius: '12px'
        },
        '& .react-grid-checkbox:checked + .react-grid-checkbox-label:before': {
            background: theme.light.accent.primary,
            [theme.darkTheme]: {
                background: theme.dark.accent.primary
            }
        }
    },
    hideAllCheckbox: {
        '& .react-grid-HeaderCell .react-grid-checkbox-container': {
            visibility: 'hidden'
        }
    },
    grid: {
        '& .react-grid-HeaderCell': {
            background: 'linear-gradient(180deg, ' + theme.light.grid.color1 + ' 0%, ' + theme.light.grid.color2 + ' 80%, ' + theme.light.grid.color3 + ' 100%)',
            fontWeight: 'normal',
            borderColor: 'transparent'
        },
        '& .react-grid-Row:hover .react-grid-Cell, & .react-grid-Row.row-selected .react-grid-Cell, & .react-grid-Row.row-context-menu .react-grid-Cell, & .react-grid-Canvas, & .react-grid-Cell, & .react-grid-Grid, & .react-grid-Main': {
            backgroundColor: 'transparent'
        },
        '& .react-grid-Header': {
            background: 'linear-gradient(180deg, ' + theme.light.grid.color1 + ' 0%, ' + theme.light.grid.color2 + ' 80%, ' + theme.light.grid.color3 + ' 100%)'
        },
        '& .react-grid-Row.react-grid-Row--even, & .react-grid-Row.react-grid-Row--even .react-grid-Cell.react-grid-Cell--frozen': {
            backgroundColor: theme.light.grid.even
        },
        '& .react-grid-Row.react-grid-Row--odd, & .react-grid-Row.react-grid-Row--odd .react-grid-Cell.react-grid-Cell--frozen': {
            backgroundColor: theme.light.grid.odd
        },
        '& .react-grid-Row:hover, & .react-grid-Row:hover .react-grid-Cell.react-grid-Cell--frozen': {
            backgroundColor: theme.light.grid.hover
        },
        '& .react-grid-Row .react-grid-Cell:hover': {
            borderColor: 'transparent !important'
        },
        '& .react-grid-Cell': {
            borderColor: 'transparent'
        },
        '& .rdg-selected': {
            border: 'none'
        },
        [theme.darkTheme]: {
            '& .react-grid-HeaderCell': {
                background: 'linear-gradient(180deg, ' + theme.dark.grid.color1 + ' 0%, ' + theme.dark.grid.color2 + ' 80%, ' + theme.dark.grid.color3 + ' 100%)',
                color: theme.dark.text.primary
            },
            '& .react-grid-Header': {
                background: 'linear-gradient(180deg, ' + theme.dark.grid.color1 + ' 0%, ' + theme.dark.grid.color2 + ' 80%, ' + theme.dark.grid.color3 + ' 100%)'
            },
            '& .react-grid-Row, & .react-grid-Row .react-grid-Cell.react-grid-Cell--frozen': {
                backgroundColor: theme.dark.primary
            },
            '& .react-grid-Row.react-grid-Row--even, & .react-grid-Row.react-grid-Row--even .react-grid-Cell.react-grid-Cell--frozen': {
                backgroundColor: theme.dark.grid.even
            },
            '& .react-grid-Row.react-grid-Row--odd, & .react-grid-Row.react-grid-Row--odd .react-grid-Cell.react-grid-Cell--frozen': {
                backgroundColor: theme.dark.grid.odd
            },
            '& .react-grid-Row:hover, & .react-grid-Row:hover .react-grid-Cell.react-grid-Cell--frozen': {
                backgroundColor: theme.dark.grid.hover
            },
            '& .react-grid-Toolbar .btn:after': {
                background: 'linear-gradient(180deg, ' + theme.dark.grid.color1 + ' 0%, ' + theme.dark.grid.color2 + ' 80%, ' + theme.dark.grid.color3 + ' 100%)'
            },
            '& .react-grid-Toolbar button.btn:hover:after': {
                background: theme.dark.grid.hover
            }
        }
    }
});

const ErrorRowsView = () => {
    const message = 'Oops, something bad happened!';
    return (
        <div style={{ textAlign: 'center' }}>
            <h3>{message}</h3>
        </div>
    );
};

var lastLoadedRow = 0;

class DrcGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0
        };
        this.getRow = this.getRow.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    EmptyRowsView = () => {
        const message = this.props.emptyRowsMessage || 'No data to show';
        return (
            <div style={{ textAlign: 'center', ...this.props.messageContainerStyles }}>
                <h3 style={{ ...this.props.messageStyles }}>{message}</h3>
            </div>
        );
    };

    getRow(i) {
        const { rows, pageSize, loadData, resultCount } = this.props;

        if (rows) {
            lastLoadedRow = rows.length;

            // don't try to load past end of data
            var targetStartIndex = Math.min(i, resultCount);

            if (targetStartIndex > lastLoadedRow) {
                loadData(lastLoadedRow, pageSize);
            }

            // Return the row if we have it
            if (i < lastLoadedRow) {
                return rows[i];
            } else {
                return { PoNumber: 'Loading' };
            }
        } else {
            // nothing loaded yet
            return null;
        }
    }

    updateDimensions() {
        if (this.props.fullHeightOffset && this.refs.MaintenanceGrid) {
            const height = this.refs.MaintenanceGrid.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const tableHeight = windowHeight - height.top - 90 - this.props.fullHeightOffset;
            if (tableHeight > 250) {
                this.setState({ height: tableHeight });
            } else {
                this.setState({ height: 250 });
            }
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    render() {
        const {
            getCellActions,
            className,
            minHeight,
            classes,
            isError,
            isRadio,
            hideAllCheckbox,
            rowHeight,
            headerRowHeight,
            rowRenderer,
            pageSize,
            resultCount,
            hideCount,
            columns,
            rows,
            autoHeight,
            fullHeightOffset,
            ...other
        } = this.props;

        var columnsArray = columns || [];
        var rowsArray = rows || [];
        var rowCount = pageSize > 0 ? resultCount : rowsArray.length;

        if (columnsArray.length <= 0 && rows.length > 0) {
            columnsArray = Object.keys(rows[0]).map((key) => {
                return { key: key, name: key };
            });
        }

        var minHeightAdjusted = minHeight;
        if (!!autoHeight) {
            //35 for header plus rows, always have at least 1 row assumed to be there.
            //Adding 25 for possible horizontal scrollbar plus a little extra for borders
            minHeightAdjusted = (Math.max(rowCount, 1) + 1) * 35 + 25;
        }

        return (
            <div className={className} ref="MaintenanceGrid">
                <div className={`${classes.root} ${classes.grid} ${isRadio ? classes.radio : ''} ${hideAllCheckbox ? classes.hideAllCheckbox : ''}`}>
                    <ReactDataGrid
                        columns={columnsArray}
                        rowGetter={pageSize > 0 ? this.getRow : (i) => rowsArray[i]}
                        rowsCount={rowCount}
                        getCellActions={getCellActions}
                        emptyRowsView={this.EmptyRowsView || (isError ? ErrorRowsView : this.EmptyRowsView)}
                        minHeight={fullHeightOffset ? this.state.height : minHeightAdjusted || 500}
                        rowHeight={rowHeight}
                        headerRowHeight={headerRowHeight}
                        rowRenderer={rowRenderer}
                        enableRowSelect={null}
                        rowScrollTimeout={null}
                        {...other}
                    />
                </div>
                {!hideCount ? (
                    <React.Fragment>
                        <br />
                        <span className={`gridCount ${classes.label}`}>{rowCount > 0 ? rowCount + ' items found' : 'No items found'}</span>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcGrid);
