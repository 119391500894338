import {
    SET_MASTER_DATA_REDIRECT_URL,
    SET_MASTER_DATA_INITIALIZED,
    STATUSES,
    WAREHOUSES,
    BERRY_TYPES,
    DELIVERY_MODES,
    CUSTOMERS,
    RECEIVING_WAREHOUSES,
    CHANGE_TYPES,
    CHANNELS,
    SALES_REPS,
    PAYMENT_TERMS,
    FSD,
    DELIVERY_TERMS,
    CUSTOMER_DELIVERY_TERMS,
    UNIT_OF_MEASUREMENTS,
    POOLWEEK,
    SET_IS_ADMIN,
    OBO
} from 'actions/MasterActions';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/PurchaseOrders/Search/';
const REDIRECT_URL = sessionStorage.getItem(REDIRECT_URL_KEY) || DEFAULT_REDIRECT_URL;

const initialState = {
    isInitialized: false,
    isAdmin: false,
    redirectUrl: REDIRECT_URL,
    status: '',
    statuses: [],
    statusOptions: [],
    warehouses: [],
    warehouseOptions: [],
    berryTypes: [],
    berryTypeOptions: [],
    deliveryModes: [],
    deliveryModeOptions: [],
    customers: [],
    customerOptions: [],
    receivingWarehouses: [],
    receivingWarehouseOptions: [],
    changeTypes: [],
    changeTypeOptions: [],
    channels: [],
    channelOptions: [],
    salesReps: [],
    salesRepOptions: [],
    paymentTerms: [],
    paymentTermsOptions: [],
    fsds: [],
    fsdOptions: [],
    deliveryTerms: [],
    deliveryTermOptions: [],
    customerIdOptions: [],
    customerDeliveryTerms: [],
    unitsOfMeasurements: [],
    unitsOfMeasurementsOptions: [],
    poolWeeks: [],
    poolWeekOptions: [],
    OBO: [],
    OBOOptions: []
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/implicit/callback' || action.payload === '/' || action.payload === '/InitializeApplication/') {
                return state;
            }

            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);

            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_MASTER_DATA_INITIALIZED:
            if (action.payload) {
                sessionStorage.setItem(REDIRECT_URL_KEY, DEFAULT_REDIRECT_URL);
            }

            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            };
        case STATUSES:
            var statusOptions = action.payload.map((status) => {
                return { value: status.Value.trim(), label: status.Value.trim() };
            });

            return Object.assign({}, state, {
                statuses: action.payload,
                statusOptions
            });
        case WAREHOUSES:
            var warehouseOptions = action.payload.map((elm) => {
                return { value: elm.WarehouseNbr, label: elm.WarehouseNbr + ' - ' + elm.Description, type: 'warehouse', companyid: elm.CompanyID };
            });

            var seenModes = [];

            warehouseOptions = warehouseOptions.filter((item) => {
                var isSeen = seenModes.includes(item.label);

                if (!isSeen) {
                    seenModes.push(item.label);
                    return true;
                }

                return false;
            });

            return Object.assign({}, state, {
                warehouses: action.payload,
                warehouseOptions
            });
        case BERRY_TYPES:
            var berryTypeOptions = action.payload.map((status) => {
                return { value: (status.Value || '').trim(), label: (status.Description || '').trim() };
            });

            return Object.assign({}, state, {
                berryTypes: action.payload,
                berryTypeOptions
            });
        case DELIVERY_MODES:
            var deliveryModeOptions = action.payload.map((deliveryMode) => {
                var value = (deliveryMode.Value || '').trim();
                var label = (deliveryMode.Label || '').trim();
                return { value: value, label: value + ' - ' + label, type: 'ModeOfDelivery' };
            });

            var seenDeliveries = [];

            deliveryModeOptions = deliveryModeOptions.filter((item) => {
                var isSeen = seenDeliveries.includes(item.value);

                if (!isSeen) {
                    seenDeliveries.push(item.value);
                    return true;
                }

                return false;
            });

            return Object.assign({}, state, {
                deliveryModes: action.payload,
                deliveryModeOptions
            });
        case CUSTOMERS:
            var filterCustomer = action.payload.filter((customer) => {
                return (customer.ShipToName || '').length > 0;
            });

            var customerOptions = filterCustomer.map((customer) => {
                return { value: customer.BillToNumber.trim(), label: (customer.BillToNumber + ' - ' + customer.BillToName || '').trim() };
            });

            var seenCustomers = [];

            customerOptions = customerOptions.filter((item) => {
                var isSeen = seenCustomers.includes(item.value);

                if (!isSeen) {
                    seenCustomers.push(item.value);
                    return true;
                }
                return false;
            });

            var customerIdOptions = filterCustomer.map((customer) => {
                return { duns: customer.DUNSNbr ? customer.DUNSNbr : '', value: customer.CustomerID, label: (customer.ShipToName || '').trim() };
            });

            seenCustomers = [];

            customerIdOptions = customerIdOptions.filter((item) => {
                var isSeen = seenCustomers.includes(item.value);

                if (!isSeen) {
                    seenCustomers.push(item.value);
                    return true;
                }

                return false;
            });

            var shipToOptions = filterCustomer.map((customer) => {
                return {
                    value: customer.ShipToNumber,
                    billtonumber: customer.BillToNumber,
                    label: customer.ShipToNumber + ' - ' + (customer.ShipToName || '').trim()
                };
            });

            seenCustomers = [];

            shipToOptions = shipToOptions.filter((item) => {
                var isSeen = seenCustomers.includes(item.value);

                if (!isSeen) {
                    seenCustomers.push(item.value);
                    return true;
                }

                return false;
            });

            return Object.assign({}, state, {
                customers: action.payload,
                customerOptions,
                shipToOptions,
                customerIdOptions
            });
        case RECEIVING_WAREHOUSES:
            var receivingWarehouseOptions = action.payload.map((rwh) => {
                return { value: (rwh.Description || '').trim(), label: (rwh.Description || '').trim() };
            });

            return Object.assign({}, state, {
                receivingWarehouses: action.payload,
                receivingWarehouseOptions
            });
        case CHANGE_TYPES:
            var changeTypeOptions = action.payload.map((changeType) => {
                return { value: (changeType.Value || '').trim(), label: (changeType.Label || '').trim() };
            });

            return Object.assign({}, state, {
                changeTypes: action.payload,
                changeTypeOptions
            });
        case CHANNELS:
            var channelOptions = action.payload.map((channel) => {
                return { value: (channel.Value || '').trim(), label: (channel.Label || '').trim(), type: 'channel' };
            });

            return Object.assign({}, state, {
                channels: action.payload,
                channelOptions
            });
        case SALES_REPS:
            var salesRepOptions = action.payload.map((salesRep) => {
                return { value: salesRep.EmployeeId, label: (salesRep.Name || '').trim() };
            });

            return Object.assign({}, state, {
                salesReps: action.payload,
                salesRepOptions
            });
        case PAYMENT_TERMS:
            var paymentTermsOptions = action.payload.map((paymentTerm) => {
                return {
                    value: paymentTerm.PaymTermCode,
                    label: paymentTerm.PaymTermCode + ' - ' + paymentTerm.Description,
                    type: 'DriscollsPaymentTerm'
                };
            });

            return Object.assign({}, state, {
                paymentTerms: action.payload,
                paymentTermsOptions
            });
        case FSD:
            var fsdOptions = action.payload.map((elm) => {
                return {
                    label: elm.LookupCode + ' - ' + elm.Description,
                    value: elm.LookupCode,
                    desc: elm.Description,
                    type: 'FSD'
                };
            });

            var fsdNameOptions = action.payload.map((elm) => {
                return {
                    label: elm.LookupCode + ' - ' + elm.Description,
                    value: elm.LookupCode,
                    type: 'FSD'
                };
            });

            return Object.assign({}, state, {
                fsd: action.payload,
                fsdOptions,
                fsdNameOptions
            });

        case DELIVERY_TERMS:
            var deliveryTermOptions = action.payload.map((elm) => {
                return {
                    label: elm.LookupCode + ' - ' + elm.Description,
                    value: elm.LookupCode,
                    type: 'DeliveryTerm'
                };
            });

            return Object.assign({}, state, {
                deliveryTerms: action.payload,
                deliveryTermOptions
            });

        case CUSTOMER_DELIVERY_TERMS:
            var custDeliveryTermOptions = action.payload.map((custDeliveryTerm) => {
                return {
                    label: custDeliveryTerm.CustDeliverTermCode,
                    value: custDeliveryTerm.CustDeliverTermCode,
                    type: 'CustDeliveryTerm'
                };
            });

            return Object.assign({}, state, {
                custDeliveryTermOptions
            });
        case UNIT_OF_MEASUREMENTS:
            var unitsOfMeasurementsOptions = action.payload.map((elm) => {
                return {
                    label: elm.FromUOMId + ' - ' + elm.ToUOMId,
                    value: elm,
                    type: 'UnitsOfMeasurements'
                };
            });

            return Object.assign({}, state, {
                unitsOfMeasurements: action.payload,
                unitsOfMeasurementsOptions
            });

        case POOLWEEK:
            var poolWeekOptions = action.payload.map((elm) => {
                return {
                    label: elm.Description,
                    value: elm.PoolWeek,
                    type: 'Poolweek'
                };
            });

            return Object.assign({}, state, {
                Poolweek: action.payload,
                poolWeekOptions
            });
        case OBO:
            action.payload.unshift({ LookupCode: '', Description: 'Select', LookupType: 'OBO', Meaning: '', EnabledFlag: 'Y' });
            var OBOOptions = action.payload.map((elm) => {
                return {
                    label: elm.Description,
                    value: elm.LookupCode, // Not sure if the mule wants the lookup code as a value in the payload to update po
                    type: 'OBO',
                    id: elm.LookupCode
                };
            });

            return Object.assign({}, state, {
                OBO: action.payload,
                OBOOptions
            });
        default:
            return state;
    }
};

export default masterReducer;
