import React from 'react';
import { BrowserRouter as Router, Route, Link, Prompt } from 'react-router-dom';
import DrcButton from './DrcButton';

class DrcFormBlocker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isBlocking: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {}

    render() {
        const { isBlocking, submitHandler, buttonProps, children } = this.props;

        return (
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    event.target.reset();
                    this.setState({
                        isBlocking: false
                    });
                }}
            >
                {children}
                <Prompt when={isBlocking} message={(location) => `Are you sure you want to go to ${location.pathname}`} />
                <p>Blocking? {isBlocking ? 'Yes, click a link or the back button' : 'Nope'}</p>

                <p>
                    <DrcButton {...buttonProps} onClick={submitHandler}>
                        Submit
                    </DrcButton>
                </p>
            </form>
        );
    }
}

export default DrcFormBlocker;
