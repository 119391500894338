import React from 'react';
import { withRouter } from 'react-router-dom';
import DrcButton from './DrcButton';

class DrcHistoryButtons extends React.Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    goForward() {
        this.props.history.goBack();
    }

    render() {
        const { className } = this.props;

        return (
            <div>
                <DrcButton isSecondary className={className} uppercaseText style={{ width: '100px', marginLeft: 0 }} onClick={this.goBack}>
                    Previous
                </DrcButton>
                <DrcButton isSecondary className={className} uppercaseText style={{ width: '100px', marginRight: 0 }} onClick={this.goForward} floatRight>
                    Next
                </DrcButton>
            </div>
        );
    }
}

export default withRouter(DrcHistoryButtons);
