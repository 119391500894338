import { Middleware } from 'one-ring';
import APIEndPoints from 'services/api';
import DataUtilities from './DataUtilities';

const PAGE_SIZE = 100;

const BODY_VARIABLE_NAMES = {
    ModeOfDelivery: 'ModeOfDelivery',
    InvoiceAccountName: 'InvoiceAccountName',
    InvoiceAccountNumber: 'InvoiceAccountNumber',
    ItemNbr: 'ItemNbr',
    Status: 'Status',
    ShipmentRequested: 'ShipmentRequested',
    DeliveryRequested: 'DeliveryRequested',
    BerryType: 'BerryType',
    Warehouse: 'Warehouse',
    Channel: 'Channel',
    SalesRep: 'Salesrep',
    SalesTaker: 'Salesrep',
    TypeOfChange: 'ChangeType',
    RWH: 'ReceivingWarehouse',
    PoNumber: 'PoNumber',
    ShipTo: 'ShipToNumber',
    EmployeeResponsible: 'EmployeeResponsible',
    Poolweek: 'Poolweek',
    BerryFieldType: 'BerryFieldType',
    POType: 'POType'
};

var getQueryString = (currentPage) => {
    return `?startPage=${currentPage || 0}&pageSize=${PAGE_SIZE}`;
};

var getSearchBody = (queryObject) => {
    var body = {};

    Object.entries(queryObject || {}).forEach(function (filter) {
        if (filter[1].isSet && filter[1].value && filter[1].value.toString().length > 0) {
            body[BODY_VARIABLE_NAMES[filter[0]]] = filter[1].value;
        }
    });

    return body;
};

const createPoRef = (po) => {
    if (!po) {
        return { ShipTo: null, PoNumber: null, PoHeaderId: null, PoStageId: null };
    }

    return { ShipTo: po.ShipTo, PoNumber: po.PoNumber, PoHeaderId: po.PoHeaderId, PoStageId: po.PoStageId, Date: po.Date };
};

const PO_REF_URL_STRING_FORMAT = ':ShipTo/:PoNumber/:PoHeaderId/:PoStageId';
const getPoRefUrlString = (poRef) => {
    return `${poRef.ShipTo}/${poRef.PoNumber}/${poRef.PoHeaderId}/${poRef.PoStageId}`; //TODO: Remove PoNumber when we switch over
};

const poRefEqual = (poRefA, poRefB) => {
    poRefA = poRefA || null;
    poRefB = poRefB || null;

    if ((poRefA === null && poRefB) || (poRefB === null && poRefA)) {
        return false;
    } else if (poRefA === poRefB) {
        return true;
    }
    return poRefA.ShipTo === poRefB.ShipTo && parseInt(poRefA.PoHeaderId) === parseInt(poRefB.PoHeaderId) && poRefA.Date === poRefB.Date;
};

const findPoIndexByRef = (poList, poRef) => {
    return poList.findIndex((po) => poRefEqual(po, poRef));
};

var tempStorage = [];

const getAndUpdatePoDetails = (
    token,
    poRefs,
    poList,
    poListAction,
    salesRepOptions,
    selectPOAction,
    selectedPo,
    shouldSelectPo = true,
    forceUpdateSelectedPo = false
) => {
    //Need to create multiple and put them all together at the end
    var apiCalls = [];
    tempStorage = [];

    poRefs.forEach((poRef) => {
        var middlewareCall = Middleware.CreateSendCall(
            'PoList',
            token,
            APIEndPoints.PO_LIST_SEARCH + SearchUtilities.GetQueryString(0),
            'POST',
            { PoNumber: poRef.PoNumber, ShipToNumber: poRef.ShipTo, Date: poRef.Date },
            {
                overrideRequestMapping: true
            }
        );

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, 0, (data) => {
                if (data.Results.length > 0) {
                    tempStorage.push(data.Results[0]);
                }
            })
        );
    });

    Middleware.SendMultiple('PoUpdate', apiCalls, () => {})
        .then((message) => {
            let newPoList = poList;
            let currentPo;
            tempStorage.forEach((data) => {
                var insertIndex = newPoList.Results.findIndex((po) => poRefEqual(data, po));

                if (selectedPo && data.PoNumber === selectedPo.PoNumber && data.ShipTo === selectedPo.ShipTo && data.Date === selectedPo.Date) {
                    //add shipto number in the condition
                    //adding Date to the above condition in order to distinguish between pos with same ship to and ponumbers
                    currentPo = data;
                }

                if (insertIndex >= 0) {
                    newPoList.Results.splice(insertIndex, 1, data);
                } else {
                    console.warn('Unable to find index to insert updates for PO');
                }
            });

            //update the reducer action
            poListAction(newPoList, false, salesRepOptions);

            //update selected PO reducer
            if (poRefs.length === 1) {
                selectPOAction(currentPo, shouldSelectPo, forceUpdateSelectedPo);
            }

            DataUtilities.ForceUpdatePoList(1000);
        })
        .catch((error) => {
            console.warn('Could not load updated PO data', error);
        });
};

const SearchUtilities = {
    GetQueryString: getQueryString,
    GetSearchBody: getSearchBody,
    CreatePoRef: createPoRef,
    GetPoRefUrlString: getPoRefUrlString,
    PoRefEqual: poRefEqual,
    FindPoIndexByRef: findPoIndexByRef,
    GetAndUpdatePoDetails: getAndUpdatePoDetails,
    PageSize: PAGE_SIZE,
    PoRefUrlStringFormat: PO_REF_URL_STRING_FORMAT
};

export default SearchUtilities;
