import { Middleware } from 'one-ring';

const changeTypesMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('LookupType', 'LookupType'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Meaning', 'Meaning'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateMapping('DisplaySequence', 'DisplaySequence'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('isDeleted', 'isDeleted'),
    Middleware.CreateArrayMapping('Results') //array name change
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('changeTypes', 'GET')) {
        Middleware.RegisterMapping('changeTypes', 'GET', changeTypesMappings);
        Middleware.RegisterMapping('changeTypes', 'POST', changeTypesMappings);
    }
}

const changeTypes = {
    ConfigureMiddleware
};

export default changeTypes;
