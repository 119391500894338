import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import DrcIcons from '../Utilities/DrcIcons';
import DrcDatagridEditor from './DrcDatagridEditor';
import DrcIconLabel from './DrcIconLabel';
import { DuDateUtilities } from 'driscolls-react-utilities';
import DrcDateRangePicker from './DrcDateRangePicker';
import DrcDatePicker from './DrcDatepicker';
import DrcTimePicker from './DrcTimePicker';
import DrcTooltip from './DrcTooltip';
// Props:
//
// columns:
// List of properties for each column in the table
// Ex: [
//   {
//     key: (String) Key for the associated row value
//     name: (String) Display value for the column
//     filter: (Boolean default false) Enables row filtering for this column default is Search
//             If defaultFilters prop is set to true(see below) all column filters will be enabled
//     filterElement: (String of ['search', 'dropdown', 'multiSelect]) Dictates the type of filter for the column
//     filterProps: (Object): {
//         options:[{value: , label:}, ... ]
//         template:(function that returns react component for multiselect dropdown item rendering)
//         placeholder: Placeholder for filter
//     filterMatchMode: choice of "contains", "endsWith", "equals", "in"
//         default: 'contains'
//
// }
//   },
//   ...
// ]
//
// rows:
// List of Objects, each is a row. Each row has key: value pairs that correlate to the keys in the columns
//      and the value gets displayed in the row
// Ex: [
//   { key:value, key:value, ... },
//   ...
// ]
//
// defaultFilters:
// Boolean default false
// Defaults all filters in the in the columns to true, enabling searching.
//   Specific filterElements can still be defined in columns.
//
// contextMenu:array of menuItems
//    label: string with display label
//    icon: string with primeicon ref
//    command: function to handle context menu selection
//
// onRowClick: function handler for row click event
//@doc https://www.primefaces.org/primereact/showcase/#/datatable/paginator
const PAGINATOR_TEMPLATE = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport';
const ROWS_PER_PAGES = [50, 100, 200];

const styles = (theme) => ({
    label: {
        float: 'right',
        [theme.darkTheme]: {
            color: '#aaa'
        },
        fontSize: '14px'
    },
    root: {
        width: '100%',
        '& .p-column-title': {
            '& span': {
                fontWeight: '600'
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            float: 'left'
        },
        '& .p-datatable-scrollable-wrapper': {
            overflowX: 'auto'
        }
    },
    grid: {
        '& .MuiInputBase-root': {
            '&:hover': {
                border: '1px solid ' + theme.light.accent.brightness(10)
            },
            color: theme.light.text.primary + ' !important',
            height: '25px !important',
            backgroundColor: theme.light.grid.color1,
            // Still need to pull out hardCoded colors and add DarkMode style
            border: '1px solid rgb(221, 221, 221)',
            borderRadius: '2px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {},
        '& .p-datatable-scrollable-header': {
            background: 'linear-gradient(180deg, ' + theme.light.grid.color1 + ' 0%, ' + theme.light.grid.color2 + ' 80%, ' + theme.light.grid.color3 + ' 100%)',
            fontWeight: 'normal',
            borderColor: 'transparent'
        },
        '& .p-datatable-thead tr th': {
            height: 35
        },
        '& .p-datatable-scrollable-wrapper': {
            borderBottom: '1px solid rgb(221, 221, 221)',
            borderTop: '1px solid rgb(221, 221, 221)',
            borderLeft: '1px solid rgb(221, 221, 221)',
            borderRight: '1px solid rgb(221, 221, 221)',
            height: '100%'
        },
        '& .p-datatable-row td': {
            height: 35,
            // overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        '& .p-datatable-row:nth-of-type(2n+1)': {
            backgroundColor: theme.light.grid.even
        },
        '& .p-datatable-row:nth-of-type(2n)': {
            backgroundColor: theme.light.grid.odd
        },
        '& .p-datatable-row:hover, & .p-datatable-row:hover .p-datatable-row.p-datatable-row--frozen': {
            backgroundColor: theme.light.grid.hover
        },
        '& .p-datatable-emptymessage td': {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
            textAlign: 'center'
        },
        '& .p-datatable-scrollable-view.p-datatable-frozen-view': {
            marginBottom: 5
        },
        '& .p-datatable .p-datatable-thead>tr>th, .p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-tfoot>tr>td': {
            padding: 4
        },
        '& .p-dropdown.p-component.p-dropdown-clearable': {
            '&:hover': {
                backgroundColor: theme.light.secondary,
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.brightness(10)
            },
            borderBottom: '1px solid rgb(221, 221, 221)',
            borderTop: '1px solid rgb(221, 221, 221)',
            borderLeft: '1px solid rgb(221, 221, 221)',
            borderRight: '1px solid rgb(221, 221, 221)',
            backgroundColor: theme.light.grid.color1
        },
        '& .p-inputtext.p-component.p-column-filter': {
            '&:hover': {
                backgroundColor: theme.light.secondary,
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.brightness(10)
            },
            borderBottom: '1px solid rgb(221, 221, 221)',
            borderTop: '1px solid rgb(221, 221, 221)',
            borderLeft: '1px solid rgb(221, 221, 221)',
            borderRight: '1px solid rgb(221, 221, 221)',
            backgroundColor: theme.light.grid.color1
        },
        '& .p-contextmenu': {
            padding: '10px',
            borderRadius: '8px',
            backgroundColor: theme.light.primary,
            boxShadow: '0px 4px 8px ' + theme.light.header.shadow
        },
        '& .p-menuitem': {
            paddingTop: '5px',
            paddingBottom: '5px'
        },
        '& .p-menuitem.p-menuitem-active': {
            backgroundColor: theme.light.grid.color1
        },
        '& .p-checkbox .p-checkbox-box': {
            width: '1.125em',
            height: '1.125em',
            lineHeight: '1.125em',
            borderRadius: '2px',
            textAlign: 'center',
            border: '1px solid',
            padding: '0px 18px 18px 0px'
        },
        '& .p-radiobutton .p-radiobutton-box': {
            width: '1.125em',
            height: '1.125em',
            lineHeight: '1.125em',
            borderRadius: '100%',
            textAlign: 'center',
            position: 'relative',
            border: '1px solid',
            padding: '10px'
        },
        '& .p-paginator-page.p-link.p-highlight': {
            textDecoration: 'underline',
            fontWeight: 'bold'
        },
        '& .p-paginator .p-link.p-disabled': {
            opacity: 0.4
        },
        [theme.darkTheme]: {
            '& .p-datatable-scrollable-header': {
                background: 'linear-gradient(180deg, ' + theme.dark.grid.color1 + ' 0%, ' + theme.dark.grid.color2 + ' 80%, ' + theme.dark.grid.color3 + ' 100%)',
                color: theme.dark.text.primary
            },
            '& .p-datatable-row:nth-of-type(2n+1)': {
                backgroundColor: theme.dark.grid.even,
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            },
            '& .p-datatable-row:nth-of-type(2n)': {
                backgroundColor: theme.dark.grid.odd
            },
            '& .p-dropdown.p-component.p-dropdown-clearable': {
                '&:hover': {
                    backgroundColor: theme.dark.secondary,
                    color: theme.dark.text.primary,
                    border: '1px solid ' + theme.dark.accent.brightness(10)
                },
                borderBottom: '1px solid rgb(221, 221, 221)',
                borderTop: '1px solid rgb(221, 221, 221)',
                borderLeft: '1px solid rgb(221, 221, 221)',
                borderRight: '1px solid rgb(221, 221, 221)',
                backgroundColor: theme.dark.grid.color1
            },
            '& .p-inputtext.p-component.p-column-filter': {
                '&:hover': {
                    backgroundColor: theme.dark.secondary + theme.important,
                    color: theme.dark.text.primary + theme.important,
                    border: '1px solid ' + theme.dark.accent.brightness(10)
                },
                color: theme.dark.text.primary,
                backgroundColor: theme.dark.grid.color1
            },
            '& .p-paginator .p-link': {
                color: theme.dark.text.primary
            }
        }
    },
    filterGrid: {
        '& .p-datatable-thead tr:only-child th': {
            height: 71,
            paddingTop: 6,
            verticalAlign: 'top'
        },
        '& .p-datatable-thead tr th': {
            height: 27
        }
    },
    inputEditor: {
        height: '90%',
        border: '1px solid #D1CBCB',
        [theme.darkTheme]: {
            backgroundColor: '#404040',
            border: '1px solid #5d5b5b',
            color: '#DDD'
        }
    },
    select: {
        '& .p-dropdown-items-wrapper': {
            backgroundColor: theme.light.primary,
            border: '1px solid ' + theme.light.accent.brightness(10),
            // overflow: 'visible',
            '&:hover': {
                border: '1px solid ' + theme.dark.accent.brightness(10)
            }
        },
        '& .p-dropdown-items': {
            padding: 0
        },
        '& .p-dropdown-item': {
            '&:hover': {
                backgroundColor: theme.light.secondary,
                color: theme.light.text.primary
            },
            padding: 5
        },
        [theme.darkTheme]: {
            '& .p-dropdown-items-wrapper': {
                backgroundColor: theme.dark.primary,
                border: '1px solid ' + theme.dark.text.primary
            },
            '& .p-dropdown-item': {
                '&:hover': {
                    backgroundColor: theme.dark.secondary,
                    color: theme.dark.text.primary
                }
            }
        }
    }
});

class DrcDataGrid extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            height: 0,
            menu: [],
            columns: [],
            selectedFilters: {},
            multiSelectOptions: {},
            currentRows: [],
            from: 0,
            to: 0,
            filteredRecords: [],
            filteredRecordsCount: 0
        };
        this.view = this.view.bind(this);
        this.delete = this.delete.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount() {
        if (this.props.contextMenu && this.props.contextMenu.length > 0) {
            this.constructContextMenu();
        }

        if (this.props.rows.length > 0) {
            this.setState({ currentRows: this.loadRows(0, this.props.pageSize) });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.dt) {
            let filteredRecords = this.dt.processData();

            if (filteredRecords.length !== this.state.filteredRecordsCount) {
                this.setState({
                    filteredRecords,
                    filteredRecordsCount: filteredRecords.length
                });
            }
        }

        if (!prevProps.rows.length && this.props.rows.length > 0) {
            this.setState({ currentRows: this.loadRows(0, this.props.pageSize) });
        }

        if (prevProps.rows.length > 0 && prevProps.rows.length !== this.props.rows.length) {
            let from = this.state.from;
            let to = this.state.to ? this.state.to : this.props.pageSize;

            this.setState({ currentRows: this.loadRows(from, to), to: this.props.pageSize });
        }
    }

    constructContextMenu() {
        var contextMenu = [];
        this.props.contextMenu.forEach((menuItem) => {
            contextMenu.push({ label: menuItem.label, icon: menuItem.icon, command: (event) => menuItem.command(event) });
        });
        this.setState({
            menu: contextMenu
        });
    }

    loadingText = () => (
        <div style={{ width: '100%' }}>
            <img style={{ width: '100%', height: '2rem' }} src={require('./Images/loading.gif')} alt="Loading..." />
        </div>
    );

    editRow = (props, columns) => {
        //stub for edit row
    };

    /**
     * Prime React Data Table does not allow row edit with custom controls.
     * So we are doing our on row edit.
     */
    //Editors inline - default ones - User can pass their own editors with columns as column.editor
    //TODO - Get the DrcComponents as editors
    inputTextEditor = (props) => {
        let column = this.props.columns.find((item) => {
            return item.key === props.field;
        });
        return props.rowData[this.props.uniqueKeyField] === this.props.rowUnderEditUniqueKey ? (
            <DrcDatagridEditor
                options={column.editorOptions || []}
                editorType={column.editorType || 'text'}
                gridProps={props}
                rowData={props.rowData}
                field={props.field}
                unique={column.isUnique}
                required={column.required}
                columnEditorValidator={column.editorValidator}
                onEditorValueCommit={this.props.onEditorValueChange}
            />
        ) : props.body ? (
            props.body(props.rowData, props)
        ) : (
            this.resolveFieldData(props.rowData, props.field)
        );
    };

    berryTypeSelector = (props) => {
        return props.rowData[this.props.uniqueKeyField] === this.props.rowUnderEditUniqueKey ? (
            <DrcDatagridEditor editorType="berryselect" gridProps={props} rowData={props.rowData} field={props.field} onEditorValueCommit={this.props.onEditorValueChange} />
        ) : props.body ? (
            props.body(props.rowData, props)
        ) : (
            this.resolveFieldData(props.rowData, props.field)
        );
    };

    resolveFieldData = (data, field) => {
        if (data && field) {
            if (this.isFunction(field)) {
                return field(data);
            } else if (field.indexOf('.') === -1) {
                return data[field];
            } else {
                let fields = field.split('.');
                let value = data;
                for (var i = 0, len = fields.length; i < len; ++i) {
                    if (value == null) {
                        return null;
                    }
                    value = value[fields[i]];
                }
                return value;
            }
        } else {
            return null;
        }
    };

    isFunction = (obj) => {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    };

    constructColumns() {
        const { columns, rows, defaultFilters, actionTemplate } = this.props;
        var columnArray = [];
        if (this.props.rowSelect) {
            columnArray.push(
                <Column
                    selectionMode={this.props.selectionMode || 'multiple'}
                    style={{ width: '20px' }}
                    className={this.props.selectedClass}
                    frozen={this.props.selectedFrozen || false}
                />
            );
        }

        if (columns && columns.length > 0) {
            Object.values(columns).map((column) => {
                var options = column.filterProps && column.filterProps.options ? column.filterProps.options : this.getOptions(rows, column.key);

                var columnFilterComponent = this.getFilterComponent(column.filterElement, column.filterProps, options, column.key);
                var filterEnabled = column.filter === undefined ? defaultFilters : column.filter;
                var filterMatchMode =
                    filterEnabled && (column.filterElement === 'dateRange' || column.filterElement === 'berryType' || column.filterElement === 'customSelectFilter')
                        ? 'custom'
                        : filterEnabled && (column.filterMatchMode === 'search' || column.filterMatchMode === undefined)
                        ? 'contains'
                        : undefined;

                let filterFunction = null;

                if (column.filterProps && column.filterProps.customFilterFunction) {
                    filterFunction = column.filterProps.customFilterFunction;
                } else {
                    if (filterEnabled && column.filterElement === 'dateRange') {
                        filterFunction = this.dateFilter;
                    }

                    if (filterEnabled && column.filterElement === 'datePicker') {
                        filterFunction = this.datePickerFilterMethod;
                    }

                    if (filterEnabled && column.filterElement === 'timePicker') {
                        filterFunction = this.timePickerFilterMethod;
                    }

                    if (filterEnabled && column.filterElement === 'berryType') {
                        filterFunction = this.berryFilter;
                    }
                }
                //In line Edit Defaults
                let columnEditor = column.editor;
                if (this.props.editable && column.editable) {
                    columnEditor = column.key.toLowerCase() === 'actions' ? null : column.editor || this.inputTextEditor;
                }

                const cssStyle = {};
                if (column.width === 0) {
                    return null;
                }
                cssStyle.width = column.width > 0 ? column.width + 'px' : column.width || '150px';
                columnArray.push(
                    <Column
                        className={this.props.classes.dropdown}
                        editorValidator={this.props.editorValidator}
                        key={column.key}
                        field={column.key}
                        header={
                            <DrcTooltip tipText={column.name}>
                                <span>{column.shortName || column.name}</span>
                            </DrcTooltip>
                        }
                        filter={filterEnabled}
                        filterElement={columnFilterComponent}
                        filterMatchMode={filterMatchMode}
                        filterFunction={filterFunction}
                        filterPlaceholder={column.filterPlaceholder || 'Filter...'}
                        loadingBody={this.props.loadingFunc || this.loadingText}
                        style={cssStyle}
                        frozen={column.frozen || false}
                        body={column.columnTemplate ? column.columnTemplate : null}
                        sortable={column.sortable}
                        editor={columnEditor}
                        editorValidator={this.props.editable ? column.editorValidator || null : null}
                    />
                );
            });
        } else if (rows.length > 0) {
            console.warn('No columns prop defined: If you wish to enable column filtering you must define your own columns.');
            Object.keys(rows[0]).map((key) => {
                columnArray.push(<Column key={key} field={key} header={key} loadingBody={this.props.loadingFunc || this.loadingText} style={{ width: '150px' }} />);
            });
        }
        if (actionTemplate) {
            columnArray.push(<Column frozen={true} body={actionTemplate} style={{ textAlign: 'center', width: '8em' }} />);
        }
        return columnArray;
    }

    dateFilter(e, range) {
        var date = new Date(e);
        //TODO
        //Check validation to make sure it doesn't throw console errors upon manual entry of column header filter
        //Might need to update this to look for invalid dates like datePickerFilterMethod
        if (range.startDate === null && range.endDate === null) {
            return true;
        }

        if (date >= range.startDate && date <= range.endDate) {
            return true;
        } else {
            return false;
        }
    }

    berryFilter(e, args) {
        if (e.key === args) {
            return true;
        }

        return false;
    }

    getOptions(rows, key) {
        var optionsArray = [];
        if (key === 'BerryType') {
            rows.map((row) => {
                if (!optionsArray.some((option) => option.value === row.BerryType.key))
                    optionsArray.push({
                        label: (
                            <DrcIconLabel style={{ display: 'flex', marginLeft: '1rem' }} icon={DrcIcons.GetSmallBerryIcon(row.BerryType.key)}>
                                {row.BerryType.key}
                            </DrcIconLabel>
                        ),
                        value: row.BerryType.key
                    });
            });
        } else
            rows.forEach((row) => {
                if (!optionsArray.some((option) => option.value === row[key])) {
                    optionsArray.push({ label: row[key], value: row[key] });
                }
            });
        return optionsArray;
    }

    getFilterComponent(element, props, options, key) {
        switch (element) {
            case 'select':
                return this.selectFilter(props, options, key);
            case 'multiSelect':
                return this.multiSelectFilter(props, options, key);
            case 'datePicker':
                return this.datePickerFilterElement(props, key);
            case 'timePicker':
                return this.timePickerFilterElement(props, key);
            case 'dateRange':
                return this.dateRangeFilter(props, key);
            case 'berryType':
            case 'customSelectFilter': //to handle custom select type filters
                return this.customSelectFilter(element, props, options, key);
            case 'search':
            default:
                return undefined;
        }
    }

    //* DatePicker *//

    /**
     * @method datePickerChange OnChange event for the datePickerFilter.
     * @param {date} filterDate Picked date that was selected in the date-picker.
     * @param {*} key Identifying key for the filter element
     * @param {function} formatter Method that can transform the selected date to match format of data in the cell's
     */
    datePickerChange(filterDate, key, formatter) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: { value: filterDate }
        };
        if (filterDate === null) selectedFilters[key] = null;
        if (formatter !== null && filterDate !== null) filterDate = formatter(filterDate);
        this.dt.filter({ value: filterDate }, key, 'custom');
        this.setState({ selectedFilters });
    }

    /**
     * @method datePickerFilterElement
     * @param {props} filterProps Props for the filterElement.
     * @param {*} key Identifying key for the filter element.
     * @returns {<DrcDatePicker />} DrcDatePicker
     */
    datePickerFilterElement(filterProps, key) {
        var formatter = filterProps ? filterProps.formatter : null;
        return (
            <DrcDatePicker
                key={key}
                selectedDate={this.state.selectedFilters[key] ? this.state.selectedFilters[key].value : null}
                onChange={(date) => this.datePickerChange(date, key, formatter)}
                required={false}
            />
        );
    }

    /**
     * @method datePickerFilterMethod Method that contains the logic for the filter.
     * @param {*} fieldDate Date from the cell of the datagrid.
     * @param {*} filterDate Date that is entered in the filter.
     * @returns {Boolean} Compared result of fieldDate and filterDate.
     */
    datePickerFilterMethod(fieldDate, filterDate) {
        if (filterDate.value === null || !(filterDate.value instanceof Date) || isNaN(filterDate.value)) {
            return true;
        }

        if (DuDateUtilities.ToPrettyDate(fieldDate) === DuDateUtilities.ToPrettyDate(filterDate.value)) {
            return true;
        }

        return false;
    }

    //* TimePicker *//
    /**
     * @method timePickerChange OnChange event for the datePickerFilter.
     * @param {Time} filterTime Picked time that was selected in the time-picker.
     * @param {*} key Identifying key for the filter element
     * @param {function} formatter Method that can transform the selected time to match format of data in the cell's
     */
    timePickerChange(filterTime, key, formatter) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: { value: filterTime }
        };
        if (filterTime === null) selectedFilters[key] = null;

        if (formatter !== null && filterTime !== null) filterTime = formatter(filterTime);
        this.dt.filter({ value: filterTime }, key, 'custom');
        this.setState({ selectedFilters });
    }

    /**
     * @method timePickerFilterElement
     * @param {props} filterProps Props for the filterElement.
     * @param {*} key Identifying key for the filter element.
     * @returns {<DrcTimePicker />} DrcTimePicker
     */
    timePickerFilterElement(filterProps, key) {
        var formatter = filterProps ? filterProps.formatter : null;
        return (
            <DrcTimePicker
                key={key}
                value={this.state.selectedFilters[key] ? this.state.selectedFilters[key].value : null}
                onChange={(time) => this.timePickerChange(time, key, formatter)}
                clearable={true}
                required={false}
            />
        );
    }

    /**
     * @method timePickerFilterMethod Method that contains the logic for the filter.
     * @param {string} fieldTime Time from the cell of the datagrid.
     * @param {string} filterTime Time that is entered in the filter.
     * @returns {Boolean} Compared result of fieldTime and filterTime.
     */
    timePickerFilterMethod(fieldTime, filterTime) {
        //TODO
        //Check validation to make sure it doesn't throw console errors upon manual entry of column header filter
        //Might need to update this to look for invalid dates like datePickerFilterMethod
        if (filterTime === null || filterTime.value === null) return true;
        if (filterTime.value === fieldTime) {
            return true;
        } else {
            return false;
        }
    }

    selectFilter = (props, options, key) => {
        return (
            <Dropdown
                key={key}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder={props.placeholder || 'Select...'}
                value={this.state.selectedFilters[key]}
                options={options}
                onChange={(e) => this.onSelectChange(e, key)}
                appendTo={document.body}
                panelClassName={this.props.classes.select}
                showClear
            />
        );
    };

    onSelectChange(event, key) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: event.value
        };
        this.dt.filter(event.value, key, 'equals');
        this.setState({ selectedFilters });
    }

    multiSelectFilter = (props, options, key) => {
        if (!this.state.multiSelectOptions[key]) {
            this.setState({
                multiSelectOptions: {
                    ...this.state.selectedFilters,
                    [key]: options
                }
            });
        }
        return (
            <Dropdown
                key={key}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder={props.placeholder || 'Select...'}
                value={this.state.selectedFilters[key] ? this.state.selectedFilters[key].join(', ') : null}
                options={this.state.multiSelectOptions[key]}
                itemTemplate={(option) => this.itemTemplate(option, props.template, key)}
                onChange={(e) => this.onMultiSelectChange(e, props, key)}
                appendTo={document.body}
                panelClassName={this.props.classes.select}
                showClear
            />
        );
    };

    dateRangeFilter(props, key) {
        return (
            <DrcDateRangePicker
                key={key}
                isDateRange={false}
                combineLabelYears={true}
                startDate={this.state.selectedFilters[key] ? this.state.selectedFilters[key].startDate : null}
                endDate={this.state.selectedFilters[key] ? this.state.selectedFilters[key].endDate : null}
                onChange={(first, second) => this.dateRangeChange(first, second, key)}
                required={false}
            />
        );
    }

    dateRangeChange(startDate, endDate, key) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: { startDate, endDate }
        };
        if (startDate === null && endDate === null) selectedFilters[key] = null;
        this.dt.filter({ startDate, endDate }, key, 'custom');
        this.setState({ selectedFilters });
    }

    customSelectFilter(element, props, options, key) {
        //makes dropdown for custom select type filters
        return (
            <Dropdown
                key={key}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder={props.placeholder || 'Select...'}
                value={this.state.selectedFilters[key]}
                options={options}
                onChange={(e) => this.onCustomSelectChange(e, key)}
                appendTo={document.body}
                panelClassName={this.props.classes.select}
                itemTemplate={props.itemTemplate || null}
                showClear
            />
        );
    }

    onCustomSelectChange(event, key) {
        //handle onChange for custom select type filters
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: event.value
        };
        this.dt.filter(event.value, key, 'custom');
        this.setState({ selectedFilters });
    }

    itemTemplate(option, template, key) {
        if (template) {
            template(option);
        } else {
            if (this.state.selectedFilters[key]) {
                if (this.state.selectedFilters[key].length > 1 && option.value === this.state.selectedFilters[key].join(', ')) {
                    return null;
                } else if (this.state.selectedFilters[key].includes(option.value)) {
                    return (
                        <div style={{ alignContent: 'center', alignItems: 'center' }}>
                            <CheckIcon />
                            <span style={{ margin: '.5em .25em 0 0' }}>{option.label}</span>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ alignContent: 'center', alignItems: 'center' }}>
                            <CloseIcon />
                            <span style={{ margin: '.5em .25em 0 0' }}>{option.label}</span>
                        </div>
                    );
                }
            } else {
                return (
                    <div style={{ alignContent: 'center', alignItems: 'center' }}>
                        <CloseIcon />
                        <span style={{ margin: '.5em .25em 0 0' }}>{option.label}</span>
                    </div>
                );
            }
        }
    }

    onMultiSelectChange(event, props, key) {
        var currentFilter = this.state.selectedFilters[key] || [],
            multiSelectOptions = props.options,
            multiSelectLabel = currentFilter.join(', '),
            multiSelectIndex;

        multiSelectOptions.forEach((option, index) => {
            if (option.value === multiSelectLabel) {
                multiSelectIndex = index;
            }
        });

        if (currentFilter.length > 1 && multiSelectIndex) {
            multiSelectOptions.splice(multiSelectIndex, 1);
        }
        if (event.value === null) {
            //checks if clear operation
            this.dt.filter(event.value, key, 'equals');
            currentFilter = undefined;
        } else {
            if (!currentFilter.includes(event.value)) {
                // Item not in array
                if (currentFilter.length > 0) {
                    // Need to add Option
                    var newOption = this.buildNewOption(currentFilter, multiSelectOptions, event.value);
                    multiSelectOptions.push(newOption);
                }
                currentFilter.push(event.value);
            } else {
                if (currentFilter.length === 1) {
                    currentFilter = null;
                } else if (currentFilter.length === 2) {
                    const index = currentFilter.indexOf(event.value);
                    if (index > -1) {
                        currentFilter.splice(index, 1);
                    }
                } else {
                    const index = currentFilter.indexOf(event.value);
                    if (index > -1) {
                        currentFilter.splice(index, 1);
                    }
                    var newOption = this.buildNewOption(currentFilter, multiSelectOptions, event.value);
                    multiSelectOptions.push(newOption);
                }
            }
            this.dt.filter(currentFilter, key, 'in');
        }

        this.setState({
            selectedFilters: {
                ...this.state.selectedFilters,
                [key]: currentFilter
            },
            multiSelectOptions: {
                ...this.state.selectedFilters,
                [key]: multiSelectOptions
            }
        });
    }

    buildNewOption(currentFilter, multiSelectOptions, newValue) {
        var associatedLabels = {},
            label = '',
            value = '';
        multiSelectOptions.forEach((filter) => {
            associatedLabels[filter.value] = filter.label;
        });
        currentFilter.forEach((filter) => {
            value += filter + ', ';
            label += associatedLabels[filter] + ', ';
        });
        value += newValue;
        label += associatedLabels[newValue];
        return { value, label };
    }

    view(data) {
        this.growl.show({ severity: 'info', summary: 'Selected', detail: data.key + ' - ' + data.label });
    }

    delete(data) {
        let list = [...this.state.data];
        list = list.filter((c) => c.key !== data.key);

        this.growl.show({ severity: 'info', summary: 'Delete', detail: data.key + ' - ' + data.label });
        this.setState({
            data: list
        });
    }

    displaySelection(data) {
        if (!data || data.length === 0) {
            return <div style={{ textAlign: 'left' }}>No Selection</div>;
        } else {
            if (data instanceof Array)
                return (
                    <ul style={{ textAlign: 'left', margin: 0 }}>
                        {data.map((data, i) => (
                            <li key={data.key}>{data.label}</li>
                        ))}
                    </ul>
                );
            else return <div style={{ textAlign: 'left' }}>Selected: {data.label}</div>;
        }
    }

    loadRows(index, length) {
        let chunk = [];
        for (let i = 0; i < length; i++) {
            chunk[i] = { ...this.props.rows[i], ...{ vin: index + i } };
        }

        return chunk;
    }

    onVirtualScroll = async (event) => {
        let page = this.props.pageSize;

        // check if the records exist
        if (this.props.rows.length < event.first + event.rows) {
            // if user scrolled more than was suppose to
            // find the difference and get all records
            if (this.props.rows.length <= event.first) {
                page = event.rows + (event.first - this.props.rows.length);
            }

            if (event.first >= this.props.totalRecords - this.props.pageSize) {
                this.setState(
                    {
                        from: event.first,
                        to: this.props.pageSize
                    },
                    async () => await this.props.loadData(page)
                );
            } else {
                this.setState(
                    {
                        from: event.first,
                        to: event.rows
                    },
                    async () => await this.props.loadData(page)
                );
            }
        } else {
            this.setState({ currentRows: this.loadRows(event.first, event.rows) });
        }
    };

    render() {
        const {
            onRowClick,
            rows,
            pageSize,
            hideCount,
            classes,
            height,
            resultCount,
            className,
            totalRecords,
            lazy,
            virtualScroll,
            gridStyles,
            paginator,
            paginatorTemplate,
            onPage,
            rowsPerPageOptions,
            currentPage,
            showReport,
            selectionChange,
            selected
        } = this.props;
        const { menu } = this.state;

        var rowsArray = rows || [],
            rowCount = pageSize > 0 ? resultCount : rowsArray.length;
        var columns = this.constructColumns();
        var gridPaginatorTemplate = paginatorTemplate || PAGINATOR_TEMPLATE;
        // since each column height is 35px + 25 for padding
        var calculatedHeight = 50 * rowCount;
        var heightAdjusted = parseInt(height);
        var isPaginatorActive = paginator && totalRecords > 0;

        if (calculatedHeight < heightAdjusted) {
            heightAdjusted = calculatedHeight + 50 + 'px'; // 50 more is to offset em's
        } else {
            heightAdjusted = height + 'px';
        }

        return (
            <div
                className={`${classes.root} ${classes.grid} ${this.props.columns.some((c) => c.filter === true) ? classes.filterGrid : null} ${gridStyles} ${
                    className ? className : null
                }`}
            >
                {menu.length > 0 && <ContextMenu model={this.state.menu} ref={(el) => (this.cm = el)} onHide={() => this.setState({ selected: null })} />}
                <DataTable
                    currentPageReportTemplate={showReport ? 'Showing {first} to {last} of {totalRecords} entries' : ''}
                    first={currentPage}
                    paginator={isPaginatorActive}
                    paginatorTemplate={gridPaginatorTemplate}
                    rowsPerPageOptions={rowsPerPageOptions || ROWS_PER_PAGES}
                    onPage={onPage}
                    editMode={this.props.editMode || null}
                    ref={(el) => (this.dt = el)}
                    value={isPaginatorActive ? rows : lazy ? this.state.currentRows : rows}
                    scrollable={true}
                    scrollHeight={heightAdjusted || '500px'}
                    scrollwidth={'100%'}
                    style={{ height: 'auto' }}
                    emptyMessage={this.props.emptyRowsMessage || 'No data to show'}
                    onContextMenu={menu.length > 0 ? (e) => this.cm.show(e.originalEvent) : null}
                    onRowClick={onRowClick}
                    rows={pageSize}
                    totalRecords={totalRecords || rows.length}
                    virtualScroll={virtualScroll}
                    onVirtualScroll={this.onVirtualScroll}
                    lazy={lazy}
                    columnResizeMode={this.props.columnResizeMode || 'expand'}
                    resizableColumns={this.props.resizableColumns || true}
                    virtualRowHeight={this.props.virtualRowHeight || 28}
                    frozenWidth={this.props.frozenWidth || null}
                    rowClassName={this.props.rowClassName || null}
                    selection={selected}
                    onSelectionChange={selectionChange}
                    // contextMenuSelection={this.state.selected}
                    // onContextMenuSelectionChange={(e) => this.setState({ selected: e.value })}
                    // onContextMenu={(e) => this.cm.show(e.originalEvent)}
                    // scrollHeight="250px"
                    // footer={this.displaySelection(this.state.selected)}
                >
                    {columns}
                </DataTable>
                {!hideCount && !showReport ? (
                    <React.Fragment>
                        <br />
                        <span className={`gridCount ${classes.label}`}>
                            {rowCount > 0
                                ? (this.state.filteredRecordsCount > 0 && this.state.filteredRecordsCount !== rowCount ? `Found: ${this.state.filteredRecordsCount} - ` : ``) +
                                  `Total : ${rowCount}`
                                : 'No items found'}
                        </span>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcDataGrid);
