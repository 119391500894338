import { Middleware } from 'one-ring';

const PriceListDetailsMappings = [
    //PriceList Details
    Middleware.CreateMapping('EmployeeResponsible', 'Employee Responsible'),
    Middleware.CreateMapping('SalesTaker', 'Sales Taker'),
    Middleware.CreateMapping('ShipTo', 'shiptonumber'),
    Middleware.CreateMapping('BillToNumber', 'BillToNumber'),
    Middleware.CreateMapping('SellToName', 'SellToName'),
    Middleware.CreateMapping('MarketProgram', 'MarketProgram'),
    Middleware.CreateMapping('LoadStartDate', 'LoadStartDate'),
    Middleware.CreateMapping('LoadEndDate', 'LoadEndDate'),
    Middleware.CreateMapping('ExpirationDate', 'ExpirationDate'),
    //PriceList Line Items
    Middleware.CreateMapping('ItemId', 'item'),
    Middleware.CreateMapping('QuotePrice', 'unitprice'),
    Middleware.CreateMapping('QuoteLoadedPrice', 'loadedprice'),
    Middleware.CreateMapping('ProductName', 'Product Description'),
    Middleware.CreateMapping('BerrySalesPlanGroup', 'BerrySalesPlanGroup'),
    Middleware.CreateMapping('DPD', 'DPD'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('SalesType', 'salestype'),
    //Object and Array Mapping
    Middleware.CreateObjectMapping('Quote', 'PriceList'),
    Middleware.CreateArrayMapping('QuoteLineItems', 'PriceListLineItems')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PriceListDetails', 'GET')) {
        Middleware.RegisterMapping('PriceListDetails', 'GET', PriceListDetailsMappings);
    }
}

const PriceListDetails = {
    ConfigureMiddleware
};

export default PriceListDetails;
