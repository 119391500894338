import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, ImplicitCallback } from '@okta/okta-react';

import {
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcPageNotFound,
    DrcBackgroundImages,
    DrcBackdrop,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcLoading,
    DrcMain,
    DrcPanel,
    Helmet,
    DrcPageLogin,
    DrcVersionInfo
} from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuThemeUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import { hideErrorDialogAction, addInfo, showLoadingScreenAction, hideLoadingScreenAction, setErrorsAction } from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from 'actions/MasterActions';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
//import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import InputIcon from '@material-ui/icons/Input';
import EventIcon from '@material-ui/icons/Event';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
//import PersonIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import MasterDataUtilities from 'data/MasterDataUtilities';
import LoggingUtilities from 'data/LoggingUtilities';
import CustomerMaintenance from 'pages/Admin/maintenance/customers/customerMaintenance';
import SearchUtilities from 'data/SearchUtilities';
import Equalizer from '@material-ui/icons/Equalizer';
import SavedFiltersDialog from './components/dialogs/SavedFiltersDialog';
const LinkSoToPo = lazy(() => import('./pages/LinkSoToPo'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const PurchaseOrders = lazy(() => import('./pages/PurchaseOrders/Search'));
const ReviewList = lazy(() => import('./pages/PurchaseOrders/ReviewList'));
const ReviewPo = lazy(() => import('./pages/PurchaseOrders/ReviewPo'));
const Creation = lazy(() => import('./pages/SalesOrder/creation'));
const Profile = lazy(() => import('./pages/User/profile'));
const Releases = lazy(() => import('./pages/Releases'));
const HealthCheck = lazy(() => import('./pages/HealthCheck'));

//Admin
const Index = lazy(() => import('./pages/Admin/index'));
const Log = lazy(() => import('./pages/Admin/log'));
const Failures = lazy(() => import('./pages/Admin/failures'));

//Data Maintenance
const Maintenance = lazy(() => import('./pages/Admin/maintenance/dataMaintenance'));
const MasterData = lazy(() => import('./pages/Admin/maintenance/masterData'));
const PaymentMethod = lazy(() => import('./pages/Admin/maintenance/paymentMethod'));
const ModesOfDelivery = lazy(() => import('./pages/Admin/maintenance/modesOfDelivery'));
const BerryType = lazy(() => import('./pages/Admin/maintenance/berryType'));
const UnitsOfMeasure = lazy(() => import('./pages/Admin/maintenance/unitsOfMeasure'));
const SalesRep = lazy(() => import('./pages/Admin/maintenance/salesRep'));
const Warehouse = lazy(() => import('./pages/Admin/maintenance/warehouse'));
const CarrierCode = lazy(() => import('./pages/Admin/maintenance/carrierCode'));
const Channel = lazy(() => import('./pages/Admin/maintenance/channel'));
const ChangeType = lazy(() => import('./pages/Admin/maintenance/changeType'));
const DemandPlanningDistrict = lazy(() => import('./pages/Admin/maintenance/demandPlanningDistrict'));
const BerrySalesPlanGroups = lazy(() => import('./pages/Admin/maintenance/berrySalesPlanGroups'));
const SalesType = lazy(() => import('./pages/Admin/maintenance/salesType'));
const Statuses = lazy(() => import('./pages/Admin/maintenance/status'));
const OBO = lazy(() => import('./pages/Admin/maintenance/onBehalfOf'));

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super'));

const defaultSecurityGroups = adminGroups.concat(window.config.OKTA_REGULAR_GROUPS || []);
const allGroups = adminGroups.concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || []);

const allLinks = [
    { title: 'Dashboard', url: '/Dashboard/', icon: <DashboardIcon /> },
    {
        title: 'Purchase Orders',
        url: '/PurchaseOrders/Search/',
        icon: <AttachMoneyIcon />,
        subLinks: [{ title: 'Review Customer Changes', url: '/PurchaseOrders/Review/', requiresRegular: true }]
    },
    { title: 'Link SO to PO', url: '/LinkSoToPo/', icon: <InputIcon />, requiresGroups: defaultSecurityGroups },
    { title: 'Customer Maintenance', url: '/Admin/Customer/All/CustomerDetails/', icon: <RoomServiceIcon /> },
    {
        title: 'Master Data',
        url: '/Admin/MasterData/',
        icon: <StorageIcon />,
        subLinks: [
            { title: 'Berry Sales Plan Groups', url: '/Admin/Maintenance/BerrySalesPlanGroups/' },
            { title: 'Berry Types', url: '/Admin/Maintenance/BerryTypes/' },
            { title: 'Carrier Codes', url: '/Admin/Maintenance/CarrierCodes/' },
            { title: 'Channels', url: '/Admin/Maintenance/Channels/' },
            { title: 'Demand Planning Districts', url: '/Admin/Maintenance/DemandPlanningDistricts/' },
            { title: 'Modes Of Delivery', url: '/Admin/Maintenance/ModesOfDelivery/' },
            { title: 'On Behalf Of', url: '/Admin/Maintenance/OBO/' },
            { title: 'Payment Methods', url: '/Admin/Maintenance/PaymentMethods/' },
            { title: 'Sales Reps', url: '/Admin/Maintenance/SalesReps/' },
            { title: 'Sales Types', url: '/Admin/Maintenance/SalesTypes/' },
            { title: 'Statuses', url: '/Admin/Maintenance/Statuses/' },
            { title: 'Units of Measure', url: '/Admin/Maintenance/UnitsOfMeasure/' },
            { title: 'Warehouses', url: '/Admin/Maintenance/Warehouses/' }
        ]
    },
    { title: 'Log Report', href: window.config.REPORTS_BASE_ADDRESS, icon: <Equalizer />, target: '_blank' },
    { title: 'Releases', url: '/Releases/', icon: <EventIcon />, requiresGroups: superAdminGroups },
    //{ title: 'Profile', url: '/User/Profile/', icon: <PersonIcon /> },
    { title: 'Sign Out', url: '/LogOut/', icon: <DirectionsRun /> }
];

const SITE_NAME = 'Customer Engagement Platform';
const SITE_ACRONYM = 'CEP - ';
const LANDING_PAGE = '/Dashboard/';

var styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'calc(100% - 50px)',
            width: '39%'
        },
        '& .MuiDialogTitle-root': {
            borderBottom: 'none !important',
            marginBottom: 0
        },
        '& .MuiDialogActions-root': {
            borderTop: 'none !important',
            marginTop: 0
        },
        '& .MuiDialogActions-root > div': {
            width: '39%',
            margin: '-8px 0'
        },
        '& .MuiTypography-h6': {
            fontSize: '2rem'
        }
    },
    noMarginLeft: { marginLeft: '0 !important' },
    noMarginRight: { marginRight: '0 !important' },
    header: {
        background: 'transparent',
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 97),
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 10),
            '& .title': {
                textShadow: 'none',
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filterPopup: false };
        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : LANDING_PAGE);
        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction);
    }

    showSavedFiltersPopup = () => {
        this.setState({ filterPopup: true });
    };

    hideFilterDialog = () => {
        this.setState({ filterPopup: false });
    };
    render() {
        return (
            <>
                <DrcBackgroundImages />
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? SITE_ACRONYM + this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security
                        issuer={window.config.OKTA_ISSUER}
                        client_id={window.config.OKTA_CLIENT_ID}
                        redirect_uri={window.location.origin + '/implicit/callback'}
                        onAuthRequired={DuAuthenticationUtilities.OnAuthRequired}
                        auto_renew={true}
                        scope={['openid', 'profile', 'email', 'MulesoftAPIAccess']}
                    >
                        <DrcSecureHeader
                            title={this.props.pageTitle.length > 0 ? SITE_ACRONYM + this.props.pageTitle : SITE_NAME}
                            allLinks={allLinks}
                            fullWidth={true}
                            className={this.props.classes.header}
                            showFilterControl={this.props.showFilterControl}
                            showSavedFiltersPopup={this.showSavedFiltersPopup}
                        />
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            {this.state.filterPopup && <SavedFiltersDialog hideFilterDialog={this.hideFilterDialog} />}
                            <Switch>
                                <Route path="/" exact render={(props) => <DrcPageLogin {...props} setPageTitle={this.props.setPageTitleAction} />} />
                                <DrcSecureGroupRoute path="/LinkSoToPo/" exact component={LinkSoToPo} groupsAllowed={defaultSecurityGroups} />
                                <DrcSecureGroupRoute path="/Dashboard/" exact component={Dashboard} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/InitializeApplication/"
                                    exact
                                    component={InitializeApplication}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path="/PurchaseOrders/Search/:PoNumber/:ShipTo/"
                                    component={PurchaseOrders}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/PurchaseOrders/Search/:Criteria/" component={PurchaseOrders} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/PurchaseOrders/Search/" component={PurchaseOrders} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/PurchaseOrders/Review/" exact component={ReviewList} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/PurchaseOrders/Review/:Criteria/"
                                    exact
                                    component={ReviewList}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path={`/PurchaseOrders/Review/${SearchUtilities.PoRefUrlStringFormat}/`}
                                    exact
                                    component={ReviewPo}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path={`/PurchaseOrders/Edit/${SearchUtilities.PoRefUrlStringFormat}/`}
                                    exact
                                    component={ReviewPo}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path={`/SalesOrder/${SearchUtilities.PoRefUrlStringFormat}/:QuoteId/`}
                                    exact
                                    component={Creation}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/User/Profile/" exact component={Profile} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Admin/" exact component={Index} groupsAllowed={adminGroups} />
                                <DrcSecureGroupRoute path="/Admin/Log/" exact component={Log} groupsAllowed={adminGroups} />
                                <DrcSecureGroupRoute path="/Admin/Failures/" exact component={Failures} groupsAllowed={adminGroups} />
                                <DrcSecureGroupRoute path="/Admin/DataMaintenance/" exact component={Maintenance} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Admin/MasterData/" exact component={MasterData} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/Admin/Customer/:CustomerID/:PageType/"
                                    exact
                                    component={CustomerMaintenance}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path="/Admin/Maintenance/PaymentMethods/"
                                    exact
                                    component={PaymentMethod}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path="/Admin/Maintenance/ModesOfDelivery/"
                                    exact
                                    component={ModesOfDelivery}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/OBO/" exact component={OBO} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/BerryTypes/" exact component={BerryType} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/Admin/Maintenance/UnitsOfMeasure/"
                                    exact
                                    component={UnitsOfMeasure}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/SalesReps/" exact component={SalesRep} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/Warehouses/" exact component={Warehouse} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/Admin/Maintenance/CarrierCodes/"
                                    exact
                                    component={CarrierCode}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/Channels/" exact component={Channel} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/ChangeTypes/" exact component={ChangeType} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/Admin/Maintenance/DemandPlanningDistricts/"
                                    exact
                                    component={DemandPlanningDistrict}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path="/Admin/Maintenance/BerrySalesPlanGroups/"
                                    exact
                                    component={BerrySalesPlanGroups}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/SalesTypes/" exact component={SalesType} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Admin/Maintenance/Statuses/" exact component={Statuses} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={superAdminGroups} />
                                <DrcSecureGroupRoute path="/HealthCheck/" exact component={HealthCheck} groupsAllowed={allGroups} />
                                <Route path="/implicit/callback" component={ImplicitCallback} />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <DrcVersionInfo allowClick={this.props.isAdmin} />
                    <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingMessage} />
                    <DrcDialog
                        isError
                        title={this.props.errorDialog.title}
                        open={this.props.errorDialog.show}
                        buttons={
                            <DrcButton isPrimary onClick={() => this.props.hideErrorDialog()}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.errorDialog.content}
                    </DrcDialog>
                </Router>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingMessage: state.rootReducer.loadingMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        showFilterControl: state.filterReducer.showFilterControl,
        filters: state.filterReducer.filters,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction()),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    addInfo: (info) => dispatch(addInfo(info)),
    showLoadingScreenAction: (title) => dispatch(showLoadingScreenAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
