import React, { Component } from 'react';
import DrcImage from './DrcImage';

class DrcBackgroundImages extends Component {
    render() {
        return (
            <React.Fragment>
                <DrcImage
                    src={require('./Images/Background_Top.png')}
                    webp={require('./Images/Background_Top.webp')}
                    className="ImgBackgroundTop"
                    style={{
                        position: 'fixed',
                        top: '5vh',
                        right: '0',
                        zIndex: '-1',
                        width: '60vw',
                        maxWidth: '400px'
                    }}
                    alt=""
                />
                <DrcImage
                    src={require('./Images/Background_Bottom.png')}
                    webp={require('./Images/Background_Bottom.webp')}
                    className="ImgBackgroundBottom"
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        zIndex: '-1',
                        width: '60vw',
                        maxWidth: '400px'
                    }}
                    alt=""
                />
            </React.Fragment>
        );
    }
}

export default DrcBackgroundImages;
