import PoList from './poList';
import UserProfile from './profile';
import QuoteDetails from './quoteDetails';
import PriceListDetails from './priceListDetails';
import PoLineItems from './poLineItems';
import MasterData from './masterData';
import Maintenance from './maintenance';
import ChangeTypes from './changeTypes';
import Status from './status';
import Allowances from './allowances';
import PaymentTerms from './paymentTerms';
import Customer from './customer';
import Channels from './channels';
import SoConversion from './soconversion';
import BerrySalesPlanGroups from './berrySalesPlanGroups';
import Item from './item';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import SalesTypes from './salesTypes';
import linkSOToPO from './linkSOToPO';
import StateInfo from './dashboard';
import savedFilters from './savedFilters';

function ConfigureAllMiddleware() {
    UserProfile.ConfigureMiddleware();
    PoList.ConfigureMiddleware();
    PoLineItems.ConfigureMiddleware();
    QuoteDetails.ConfigureMiddleware();
    PriceListDetails.ConfigureMiddleware();
    MasterData.ConfigureMiddleware();
    Maintenance.ConfigureMiddleware();
    ChangeTypes.ConfigureMiddleware();
    Channels.ConfigureMiddleware();
    Status.ConfigureMiddleware();
    Allowances.ConfigureMiddleware();
    PaymentTerms.ConfigureMiddleware();
    Customer.ConfigureMiddleware();
    BerrySalesPlanGroups.ConfigureMiddleware();
    SalesTypes.ConfigureMiddleware();
    SoConversion.ConfigureMiddleware();
    Item.ConfigureMiddleware();
    linkSOToPO.ConfigureMiddleware();
    StateInfo.ConfigureMiddleware();
    savedFilters.ConfigureMiddleware();

    Middleware.RegisterDefaultPayloadHandler((token) => {
        var email = DuAuthenticationUtilities.GetEmail(token);
        return { LastUpdatedBy: email || 'Bad Token', LastUpdatedDateTime: new Date().toISOString() };
    });
}

const setup = {
    ConfigureAllMiddleware
};

export default setup;
