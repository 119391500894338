const icons = {
    Black_Small_Webp: require('./Images/Black_Small.webp'),
    Blue_Small_Webp: require('./Images/Blue_Small.webp'),
    Rasp_Small_Webp: require('./Images/Rasp_Small.webp'),
    Straw_Small_Webp: require('./Images/Straw_Small.webp'),
    Black_Medium_Webp: require('./Images/Black_Medium.webp'),
    Blue_Medium_Webp: require('./Images/Blue_Medium.webp'),
    Rasp_Medium_Webp: require('./Images/Rasp_Medium.webp'),
    Straw_Medium_Webp: require('./Images/Straw_Medium.webp')
};

export default icons;
