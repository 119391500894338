class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;

    //Master Data
    static CUSTOMERS = this.BASE + 'masterdata/customers';
    static RWH = this.BASE + 'masterdata/rwh';
    static PAYMENTMETHOD = this.BASE + 'masterdata/paymentmethod';
    static CUSTOMER_PAYMENTMETHOD = this.BASE + 'masterdata/customerpaymentmethod';
    static ALLOWANCESCHARGECODES = this.BASE + 'masterdata/allowanceschargecodes';
    static CUSTOMER_CHARGECODES = this.BASE + 'masterdata/customerchargecodes';
    static PAYMENT_TERMS = this.BASE + 'masterdata/paymentterms';
    static WAREHOUSE = this.BASE + 'masterdata/warehouse';
    static CUSTOMER_WAREHOUSES = this.BASE + 'masterdata/customerwarehouse';
    static DEMANDPLANNINGDISTRICT = this.BASE + 'masterdata/demandplanningdistrict';
    static MODES_OF_DELIVERY = this.BASE + 'masterdata/modeofdelivery';
    static CUSTOMER_MODE_OF_DELIVERY = this.BASE + 'masterdata/customermodeofdelivery';
    static CARRIERCODES = this.BASE + 'masterdata/carriercodes';
    static ITEM = this.BASE + 'masterdata/item';
    static CUSTOMER_ITEMS_ALL = this.BASE + 'masterdata/customeritems';
    static UNITOFMEASURE = this.BASE + 'masterdata/itemuomconversion';
    static BERRY_TYPE = this.BASE + 'masterdata/berrytype';
    static CHANNEL = this.BASE + 'masterdata/channel';
    static SALES_REP = this.BASE + 'masterdata/salesrep';
    static STATUS = this.BASE + 'masterdata/status';
    static CHANGE_TYPES = this.BASE + 'masterdata/changetype';
    static BERRY_SALES_PLAN_GROUPS = this.BASE + 'masterdata/berrysalesplangroup';
    static SALESTYPE = this.BASE + 'masterdata/salestype';
    static FSD = this.BASE + 'masterdata/fsd';
    static DELIVERY_TERMS = this.BASE + 'masterdata/deliveryterms';
    static CUSTOMER_DELIVERY_TERMS = this.BASE + 'masterdata/customerdeliveryterms';
    static POOLWEEK = this.BASE + 'masterdata/poolweek';
    static OBO = this.BASE + 'masterdata/obo';
    static GET_USER_PROFILE = (userName) => {
        return `${this.BASE}userprofile?email=${userName}`;
    };
    static GET_ITEM_DETAILS = (itemnbr, companyid, berrytype) => {
        return `${this.BASE}masterdata/item?itemnbr=${itemnbr}&companyid=${companyid}&berrytype=${berrytype}`;
    };
    static GET_ITEMS_FOR_COMPANY = (companyid) => {
        return `${this.ITEM}?companyid=${companyid}&itemtype=FRUIT`;
    };
    static GET_ITEMS_FOR_FILTER = (berrytype, customerid) => {
        return `${this.ITEM}?itemtype=FRUIT&berrytype=${berrytype}&invoiceacntnbr=${customerid}`;
    };
    static GET_ITEMS_FOR_MASS_UPDATE = (berrytype, companyid) => {
        return `${this.ITEM}?itemtype=FRUIT&berrytype=${berrytype}&companyid=${companyid}`;
    };
    static GET_CUSTOMER_ITEM_IDS = (dunsnbr, itemnbr) => {
        return `${this.CUSTOMER_ITEMS_ALL}?dunsnbr=${dunsnbr}&itemnbr=${itemnbr}`;
    };
    //Purchase Orders
    static PO_LIST_SEARCH = this.BASE + 'purchaseorders/matchedquotes';
    static PO_MASS_UPDATE = this.BASE + 'purchaseorders/bulkupdate';
    static PO_SO_MAPPING = this.BASE + 'purchaseorders/posomapping';

    static PO_REDIRECT = (PoNumber) => {
        return this.BASE + `purchaseorders/${PoNumber}/redirect`;
    };

    static PO_DETAILS = (poRef) => {
        return `${this.BASE}purchaseorders/${poRef.PoNumber}/lineitemdetails?headerid=${poRef.PoHeaderId}`;
    };
    static PO_UPDATE_HEADER = (poRef) => {
        return `${this.BASE}purchaseorders/${poRef.PoHeaderId}/poheader`;
    };
    static PO_MATCHED_QUOTES = (poRef) => {
        return `${this.BASE}purchaseorders/${poRef.PoNumber}/nearestquotes?headerid=${poRef.PoHeaderId}`;
    };
    static CUSTOMER_ITEMS = (dunsNbr) => {
        return `${this.BASE}masterdata/customeritems?dunsnbr=${dunsNbr}`;
    };

    static SO_DETAILS = (SoNumber, CompanyId) => {
        return this.BASE + `salesorder/${SoNumber}?company=${CompanyId}`;
    };

    static LINK_SO_TO_PO = this.BASE + 'purchaseorders/posolinking';

    //Staging A.K.A customer changes
    static PO_STAGING_UPDATE = this.BASE + 'purchaseorders/stage';
    static PO_STAGING_DETAILS = (poRef) => {
        return `${this.BASE}purchaseorders/${poRef.PoNumber}/detailstage?postageid=${poRef.PoStageId}`;
    };

    //Quotes
    static QUOTE = (quoteId) => {
        return `${this.BASE}quotes/${quoteId}`;
    };
    static PRICE = (priceId) => {
        return `${this.BASE}pricelist/${priceId}`;
    };
    static REVIEW = this.BASE + 'purchaseorders/stage?status=Open';

    //SO
    static GET_SO_CONVERSION_DATA = (poRef, matchType, refNumber) => {
        return `${this.BASE}purchaseorders/${poRef.PoNumber}/soconfirmation?matchtype="${matchType}"&poheaderid=${poRef.PoHeaderId}&refnbr="${refNumber}"`;
    };

    static POST_SO_CONVERSION = `${this.BASE}salesorder/confirmation`;

    //Dashboard
    static GET_DASHBOARD_INFO = `${this.BASE}purchaseorders/statusinfo`;

    //User Filters

    static GET_USER_FILTERS_AND_GRID_SETTINGS = (loggedInUser) => {
        return `${this.BASE}purchaseorders/savedsearch?emailid=${loggedInUser}`;
    };

    static ADD_USER_FILTER = `${this.BASE}purchaseorders/savedsearch`;
}

export default APIEndPoints;
