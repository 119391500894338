import { createMuiTheme } from '@material-ui/core/styles';
import { DuThemeUtilities } from 'driscolls-react-utilities';

const createSimpleTheme = (lightPrimary, darkPrimary) => {
    return createMuiTheme(DuThemeUtilities.CreateSimpleThemeConfig(lightPrimary, darkPrimary));
};

const DrcThemeUtilities = {
    CreateSimpleTheme: createSimpleTheme
};

export default DrcThemeUtilities;
