import { Middleware } from 'one-ring';

const ChannelsMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Label', 'Label'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Meaning', 'Meaning'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateMapping('displaySequence', 'displaySequence'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('lastUpdatedNameAndTime', 'lastUpdatedNameAndTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateArrayMapping('Results')
];
function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Channels', 'GET')) {
        Middleware.RegisterMapping('Channels', 'GET', ChannelsMappings);
        Middleware.RegisterMapping('Channels', 'POST', ChannelsMappings);
    }
}

const Channels = {
    ConfigureMiddleware
};

export default Channels;
