import React from 'react';
import DrcImage from './DrcImage';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        textAlign: 'center',
        '& img': {
            transform: 'scale(0.7)',
            animation: '$di-loading 1s linear infinite'
        },
        '& img:nth-of-type(2)': {
            animationDelay: '0.2s'
        },
        '& img:nth-of-type(3)': {
            animationDelay: '0.4s'
        },
        '& img:nth-of-type(4)': {
            animationDelay: '0.6s'
        }
    },
    hidden: {
        '& img': {
            visibility: 'hidden'
        }
    },
    '@keyframes di-loading': {
        '0%': { transform: 'scale(0.7)' },
        '25%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(0.7)' }
    }
});

class DrcLoading extends React.Component {
    render() {
        const { size, text, style, classes, hidden } = this.props;

        var imgSize = size || 'Medium';

        return (
            <div className={`${classes.root} ${hidden ? classes.hidden : ''}`}>
                <span style={{ margin: '0 auto' }}>
                    <DrcImage
                        src={imgSize === 'Medium' ? require('./Images/Black_Medium.png') : require('./Images/Black_Small.png')}
                        webp={imgSize === 'Medium' ? require('./Images/Black_Medium.webp') : require('./Images/Black_Small.webp')}
                        alt="Blackberry"
                    />
                    <DrcImage
                        src={imgSize === 'Medium' ? require('./Images/Blue_Medium.png') : require('./Images/Blue_Small.png')}
                        webp={imgSize === 'Medium' ? require('./Images/Blue_Medium.webp') : require('./Images/Blue_Small.webp')}
                        alt="Blueberry"
                    />
                    <DrcImage
                        src={imgSize === 'Medium' ? require('./Images/Rasp_Medium.png') : require('./Images/Rasp_Small.png')}
                        webp={imgSize === 'Medium' ? require('./Images/Rasp_Medium.webp') : require('./Images/Rasp_Small.webp')}
                        alt="Raspberry"
                    />
                    <DrcImage
                        src={imgSize === 'Medium' ? require('./Images/Straw_Medium.png') : require('./Images/Straw_Small.png')}
                        webp={imgSize === 'Medium' ? require('./Images/Straw_Medium.webp') : require('./Images/Straw_Small.webp')}
                        alt="Strawberry"
                    />
                </span>
                {text ? (
                    <span
                        style={{
                            display: 'block',
                            fontSize: imgSize === 'Medium' ? '1.25rem' : '1rem',
                            ...style
                        }}
                    >
                        {text}
                    </span>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcLoading);
