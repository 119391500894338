import { Middleware } from 'one-ring';

const QuoteDetailsMappings = [
    //Quote Details
    Middleware.CreateMapping('QuotePeriod', 'QuotePeriod'),
    Middleware.CreateMapping('MarketProgram', 'MarketProgram'),
    Middleware.CreateMapping('SellToName', 'SellToName'),
    Middleware.CreateMapping('EmployeeResponsible', 'Employee Responsible'),
    Middleware.CreateMapping('SalesTaker', 'Sales Taker'),
    Middleware.CreateMapping('DeliveryMode', 'DeliveryMode'),
    Middleware.CreateMapping('LoadStartDate', 'LoadStartDate'),
    Middleware.CreateMapping('LoadEndDate', 'LoadEndDate'),
    Middleware.CreateMapping('ExpirationDate', 'ExpirationDate'),
    //Quote Line Items
    Middleware.CreateMapping('ItemId', 'item'),
    Middleware.CreateMapping('QuoteQuantity', 'QtyOrdered'),
    Middleware.CreateMapping('VolumeAvailable', 'Qty Available'),
    Middleware.CreateMapping('QuotePrice', 'unitprice'),
    Middleware.CreateMapping('QuoteLoadedPrice', 'loadedprice'),
    Middleware.CreateMapping('ProductName', 'Product Description'),
    Middleware.CreateMapping('DPD', 'DPD'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('SalesType', 'salestype'),
    //Object and Array Mapping
    Middleware.CreateObjectMapping('Quote', 'Quotes'),
    Middleware.CreateArrayMapping('QuoteLineItems', 'QuoteLineItems')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('QuoteDetails', 'GET')) {
        Middleware.RegisterMapping('QuoteDetails', 'GET', QuoteDetailsMappings);
    }
}

const QuoteDetails = {
    ConfigureMiddleware
};

export default QuoteDetails;
