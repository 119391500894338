import { Middleware } from 'one-ring';

const CustomerMappings = [
    Middleware.CreateIndexMapping('Id', 'Id'),
    Middleware.CreateMapping('ExternalCustomerId', 'ExternalCustomerID'),
    Middleware.CreateMapping('ExternalCustomerName', 'ExternalCustomerName'),
    Middleware.CreateMapping('CustomerID', 'CustomerID'),
    Middleware.CreateMapping('CustomerName', 'CustomerName'),
    Middleware.CreateSortMapping('BillToName', 'BillToName'),
    Middleware.CreateMapping('BillToNumber', 'BillToNumber'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('ShipToNumber', 'ShipToNumber'),
    Middleware.CreateMapping('DUNSNbr', 'DUNSNbr'),
    Middleware.CreateMapping('GLNNbr', 'GLNNbr'),
    Middleware.CreateMapping('ISAID', 'ISAID'),
    Middleware.CreateMapping('BuyerVendorNbr', 'BuyerVendorNbr'),
    Middleware.CreateMapping('DriscollsID', 'DriscollsID'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('ReceivingWarehouse', 'ReceivingWarehouse'),
    Middleware.CreateMapping('SalesRepId', 'SalesRepId'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('Enabled', 'Enabled'),
    Middleware.CreateArrayMapping('Results')
];

const CustomerDetailsMapping = [
    Middleware.CreateMapping('ExternalCustomerID', 'ExternalCustomerID'),
    Middleware.CreateMapping('ExternalCustomerName', 'ExternalCustomerName'),
    Middleware.CreateMapping('CustomerID', 'CustomerID'),
    Middleware.CreateMapping('CustomerName', 'CustomerName'),
    Middleware.CreateMapping('BillToName', 'BillToName'),
    Middleware.CreateMapping('BillToNumber', 'BillToNumber'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('ShipToNumber', 'ShipToNumber'),
    Middleware.CreateMapping('GLNNbr', 'GLNNbr'),
    Middleware.CreateMapping('ISAID', 'ISAID'),
    Middleware.CreateMapping('DUNSNbr', 'DUNSNbr'),
    Middleware.CreateMapping('BuyerVendorNbr', 'BuyerVendorNbr'),
    Middleware.CreateMapping('DriscollsID', 'DriscollsID'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('PaymentTerm', 'PaymentTerm'),
    Middleware.CreateMapping('DeliveryTerm', 'DeliveryTerm'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('DeliveryMode', 'DeliveryMode'),
    Middleware.CreateArrayMapping('Results')
];

const CustomerDeliveryTermsMapping = [
    Middleware.CreateMapping('DeliveryTermCode', 'DeliveryTermCode'),
    Middleware.CreateMapping('CustDeliverTermCode', 'CustDeliverTermCode'),
    Middleware.CreateMapping('CustomerId', 'CustomerId'),
    Middleware.CreateMapping('CustDeliverTermDesc', 'CustDeliverTermDesc'),
    Middleware.CreateArrayMapping('Results')
];

const CustomerItemsMapping = [
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('ItemType', 'itemType'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('CustomerItemId', 'CustomerItemId'),
    Middleware.CreateMapping('ShipToNumber', 'ShipToNumber'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('CustomerItemDescription', 'CustomerItemDescription'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('UPCNbr', 'UPCNbr'),
    Middleware.CreateMapping('DUNSNbr', 'DUNsNbr'),
    Middleware.CreateMapping('GTIN', 'GTIN'),
    Middleware.CreateMapping('Status', 'status'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateArrayMapping('Results')
];

const CustomerItemsforAddingMapping = [
    Middleware.CreateMapping('CustomerItemId', 'customerItemId'),
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('DUNSNbr', 'DUNsNbr'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('CustomerItemDescription', 'CustomerItemDescription'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateArrayMapping('Results')
];

const CustomerModeOfDeliveryMappings = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('CustomerDeliveryCode', 'CustomerDeliveryCode'),
    Middleware.CreateMapping('CustomerId', 'CustomerId'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateArrayMapping('Results')
];

const CustomerWarehouseMapping = [
    Middleware.CreateMapping('CustomerId', 'customerId'),
    Middleware.CreateMapping('ShipToNumber', 'ShipToNumber'),
    Middleware.CreateMapping('ReceivingWarehouse', 'ReceivingWarehouse'),
    Middleware.CreateMapping('WarehouseDescription', 'WarehouseDescription'),
    Middleware.CreateMapping('Address', 'Address'),
    Middleware.CreateMapping('City', 'City'),
    Middleware.CreateMapping('ZipCode', 'ZipCode'),
    Middleware.CreateMapping('Country', 'Country'),
    Middleware.CreateMapping('State', 'State'),
    Middleware.CreateMapping('DUNSNbr', 'DUNSNbr'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Customer', 'GET')) {
        Middleware.RegisterMapping('Customer', 'GET', CustomerMappings, true);
        Middleware.RegisterMapping('Customer', 'POST', CustomerMappings);
    }

    if (!Middleware.CheckMappingExists('CustomerDetails', 'GET')) {
        Middleware.RegisterMapping('CustomerDetails', 'GET', CustomerDetailsMapping);
        Middleware.RegisterMapping('CustomerDetails', 'POST', CustomerDetailsMapping);
        Middleware.RegisterMapping('CustomerDetails', 'DELETE', CustomerDetailsMapping);
    }

    if (!Middleware.CheckMappingExists('CustomerDeliveryTerms', 'GET')) {
        Middleware.RegisterMapping('CustomerDeliveryTerms', 'GET', CustomerDeliveryTermsMapping);
        Middleware.RegisterMapping('CustomerDeliveryTerms', 'POST', CustomerDeliveryTermsMapping);
        Middleware.RegisterMapping('CustomerDeliveryTerms', 'DELETE', CustomerDeliveryTermsMapping);
    }

    if (!Middleware.CheckMappingExists('CustomerItems', 'GET')) {
        Middleware.RegisterMapping('CustomerItems', 'GET', CustomerItemsMapping);
        Middleware.RegisterMapping('CustomerItems', 'POST', CustomerItemsMapping);
        Middleware.RegisterMapping('CustomerItems', 'DELETE', CustomerItemsMapping);
    }

    if (!Middleware.CheckMappingExists('CustomerItemsForAdding', 'GET')) {
        Middleware.RegisterMapping('CustomerItemsForAdding', 'GET', CustomerItemsforAddingMapping);
        Middleware.RegisterMapping('CustomerItemsForAdding', 'POST', CustomerItemsforAddingMapping);
        Middleware.RegisterMapping('CustomerItemsForAdding', 'DELETE', CustomerItemsforAddingMapping);
    }

    if (!Middleware.CheckMappingExists('CustomerModeOfDelivery', 'GET')) {
        Middleware.RegisterMapping('CustomerModeOfDelivery', 'GET', CustomerModeOfDeliveryMappings);
        Middleware.RegisterMapping('CustomerModeOfDelivery', 'POST', CustomerModeOfDeliveryMappings);
        Middleware.RegisterMapping('CustomerModeOfDelivery', 'DELETE', CustomerModeOfDeliveryMappings);
    }

    if (!Middleware.CheckMappingExists('CustomerWarehouse', 'GET')) {
        Middleware.RegisterMapping('CustomerWarehouse', 'GET', CustomerWarehouseMapping);
        Middleware.RegisterMapping('CustomerWarehouse', 'POST', CustomerWarehouseMapping);
        Middleware.RegisterMapping('CustomerWarehouse', 'DELETE', CustomerWarehouseMapping);
    }
}

const Customer = {
    ConfigureMiddleware
};

export default Customer;
