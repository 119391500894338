import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DrcIconLabel from './DrcIconLabel';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const styles = (theme) => ({
    item: {
        userSelect: 'none',
        padding: theme.spacing(1),
        margin: theme.spacing(0, 0, 1, 0),
        backgroundColor: theme.light.secondary,
        borderRadius: theme.spacing(0.5),
        [theme.darkTheme]: {
            backgroundColor: theme.dark.secondary
        }
    },
    icon: {
        float: 'right',
        color: '#222',
        [theme.darkTheme]: {
            color: '#5e5e5e'
        }
    },
    iconLabel: {
        fontSize: '1.2em'
    }
});

class DrcDragList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.children.map((child) => ({
                id: child.id,
                content: (
                    <DrcIconLabel icon={<DragIndicatorIcon className={this.props.classes.icon} />} titleStyle={{ margin: 0 }}>
                        <span className={this.props.classes.iconLabel}>{child.content}</span>
                    </DrcIconLabel>
                )
            }))
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    // this is used when the list is dynamic and user adds records on the fly
    componentDidUpdate(prevProps) {
        if (prevProps.children && prevProps.children.length !== this.props.children.length) {
            this.setState({
                items: this.props.children.map((child) => ({
                    id: child.id,
                    content: (
                        <DrcIconLabel icon={<DragIndicatorIcon className={this.props.classes.icon} />} titleStyle={{ margin: 0 }}>
                            <span className={this.props.classes.iconLabel}>{child.content}</span>
                        </DrcIconLabel>
                    )
                }))
            });
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(this.state.items, result.source.index, result.destination.index);

        this.setState({
            items
        });

        if(this.props.onOrderChange){
            this.props.onOrderChange(items)
        }
    }

    render() {
        const { maxWidth, style, children, error, classes, itemStyle } = this.props;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {this.state.items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={`${classes.item} ${itemStyle}`}>
                                            {item.content}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default withStyles(styles)(DrcDragList);
